import axios from "axios";
import history from "../history";
import {
  AUTH_ERROR,
  ERROR_MESSAGE,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  VALIDATION_ERROR,VALIDATION_AUTH_ERROR,TOTAL_SIZE,DOWNLOADED,PERCENT
} from "../reducers/type";
import api from "./apis/index";
import moment from "moment";
import { message } from "antd";

export const getCmsListAdmin = () => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const response = await api(localStorage.getItem("token")).get(
        "v1/content/admin/all"
      );
  
      dispatch({ type: LOADED });
      return response.data.data;
    }catch (err) {
      if (err?.response?.status === 400||err?.response?.status === 409) {
  
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
      } else if (err?.response?.status === 422) {
        err.response.data.errors.map(item => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
        });
      } else if (err?.response?.status === 404) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
      } else if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push('/login');
      } else if (err.message === 'Network Error') {
        history.push('/networkError');
      } else {
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
      }
      dispatch({ type: LOADED });
    }
  };


  export const getCmsDetailsAdmin = (id) => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const response = await api(localStorage.getItem("token")).get(
        `v1/content/admin/${id}`
      );
    console.log(response,"response")
      if (response.status === 200) {
        dispatch({ type: LOADED });
        return response.data.data[0];
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        err.response.data.errors.map((item) => {
          dispatch({
            type: VALIDATION_ERROR,
            payload: [{ message: item.message, path: item.path[0] }],
          });
        });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push("/login");
      }
      if (err.message == "Network Error") {
        history.push("/networkError");
        return;
      }
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response ? err.response.data.message : history.push("/opps"),
      });
      dispatch({ type: LOADED });
    }
  };

  export const getUploadAdmin = (id,content,values) => async (dispatch) => {
    dispatch({ type: LOADING });
    const data = new FormData();
    const object = {
      id:id,
      admin_types: values.admin_type,
      name_admin: values.name,
      edit_upload_medium: values.edit_upload_medium||"",
      edit_upload_status: true,
      edit_upload_drive_link: values.edit_upload_drive_link||"",
  
    };
    if(content) data.append("upload_file", content);
      dispatch({ type: LOADED });
  
        try {
          const response = await api(localStorage.getItem("token")).post(
            `v1/content/admin/upload`, data,{
              headers: {
                "content-type": "multipart/form-data",
                'authorization': `Bearer ${localStorage.getItem("token")}`,
              },
                params:object
            }
          );
        console.log(response,'lol..............................')
          if (response.status === 201) {
            dispatch({ type: LOADED });
            message.success(" Content upload Succesfully");
            // return history.push('/cmslist')
            return response.status
          }
        } catch (err) {
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
            localStorage.clear();
            history.push("/login");
          }
          if (err.message == "Network Error") {
            history.push("/networkError");
            return;
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response ? err.response.data.message : history.push("/opps"),
          });
          dispatch({ type: LOADED });
        }
        
      
  
  };

  export const getUploadAdmindata = (id,values) => async (dispatch) => {
    dispatch({ type: LOADING });
    console.log(id,values,"id,content,values")
    // const data = new FormData();
    // if(content) data.append("upload_file", content);
    const object = {
      uid:id,
      admin_types: values.admin_type||"another",
      name_admin: values.name_admin||"",
      edit_upload_medium: values.edit_upload_medium,
      delivery_status: values.delivery_status,
      edit_upload_status: true||"",
      edit_upload_drive_link: values.edit_upload_drive_link||"",
  
    };
    try {
      const response = await api(localStorage.getItem("token")).post(
        `v1/content/admin/upload`, {...object}
      );
    
      if (response.status === 201) {
        dispatch({ type: LOADED });
        message.success(" Content upload Succesfully");
        return response.status
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        err.response.data.errors.map((item) => {
          dispatch({
            type: VALIDATION_ERROR,
            payload: [{ message: item.message, path: item.path[0] }],
          });
        });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push("/login");
      }
      if (err.message == "Network Error") {
        history.push("/networkError");
        return;
      }
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response ? err.response.data.message : history.push("/opps"),
      });
      dispatch({ type: LOADED });
    }
  };

  export const getDeliveryStatusAdmin = (id,values) => async (dispatch) => {
    dispatch({ type: LOADING });
    console.log(id,values,"id,content,values")
    // const data = new FormData();
    // if(content) data.append("upload_file", content);
    const object = {
      uid:id,
      delivery_status: values.delivery_status||"",
      
  
    };
    
    try {
      const response = await api(localStorage.getItem("token")).put(
        `v1/content/admin/delivery-status`, {...object}
      );
      if (response.status === 201) {
        dispatch({ type: LOADED });
        message.success(" Delivery status update Succesfully");
        return response.status
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        err.response.data.errors.map((item) => {
          dispatch({
            type: VALIDATION_ERROR,
            payload: [{ message: item.message, path: item.path[0] }],
          });
        });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push("/login");
      }
      if (err.message == "Network Error") {
        history.push("/networkError");
        return;
      }
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response ? err.response.data.message : history.push("/opps"),
      });
      dispatch({ type: LOADED });
    }
  };


  export const deleteEditetContent = (id) => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const response = await api(localStorage.getItem("token")).delete(
        `v1/content/admin/delete/edit/${id}`
      );
      dispatch({ type: LOADED });
      return response.status;
    }catch (err) {
      if (err?.response?.status === 400||err?.response?.status === 409) {
  
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
      } else if (err?.response?.status === 422) {
        err.response.data.errors.map(item => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
        });
      } else if (err?.response?.status === 404) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
      } else if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push('/login');
      } else if (err.message === 'Network Error') {
        history.push('/networkError');
      } else {
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
      }
      dispatch({ type: LOADED });
    }
  };

  export const deleteEditetContentMain = (id) => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const response = await api(localStorage.getItem("token")).delete(
        `v1/content/admin/delete/raw/${id}`
      );
      dispatch({ type: LOADED });
      return response.status;
    }catch (err) {
      if (err?.response?.status === 400||err?.response?.status === 409) {
  
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
      } else if (err?.response?.status === 422) {
        err.response.data.errors.map(item => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
        });
      } else if (err?.response?.status === 404) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
      } else if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push('/login');
      } else if (err.message === 'Network Error') {
        history.push('/networkError');
      } else {
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
      }
      dispatch({ type: LOADED });
    }
  };