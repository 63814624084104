import axios from "axios";
import history from "../history";
import {
  AUTH_ERROR,
  ERROR_MESSAGE,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  VALIDATION_AUTH_ERROR,
  VALIDATION_ERROR,
} from "../reducers/type";
import api from "./apis/index";

export const createRole = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  const obj = {
    name: data.role,
    active: true,
    user_management: data.user_management===true?'a':'i',
    end_user_management: data.end_user_management===true?'a':'i' ,
    accounts_management: data.accounts_management===true?'a':'i' ,
    cms_management: data.cms_management===true?'a':'i',
  };
  try {
    const response = await api(localStorage.getItem("token")).post(
      "/role/create",
      { ...obj }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      history.push("/rolelist");
    }
  } catch (err) {
    if (err?.response?.status === 400||err?.response?.status === 409) {

      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
    } else if (err?.response?.status === 422) {
      err.response.data.errors.map(item => {
        dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
      });
    } else if (err?.response?.status === 404) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
    } else if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push('/login');
    } else if (err.message === 'Network Error') {
      history.push('/networkError');
    } else {
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
    }
    dispatch({ type: LOADED });
  }
};

export const getRoles = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/role/all"
    );
    dispatch({ type: LOADED });
    return response.data.data;
  }catch (err) {
    if (err?.response?.status === 400||err?.response?.status === 409) {

      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
    } else if (err?.response?.status === 422) {
      err.response.data.errors.map(item => {
        dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
      });
    } else if (err?.response?.status === 404) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
    } else if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push('/login');
    } else if (err.message === 'Network Error') {
      history.push('/networkError');
    } else {
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
    }
    dispatch({ type: LOADED });
  }
};

export const getRoleById = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const response = await api(localStorage.getItem("token")).get(
      `/role/${id}`
    );
    dispatch({ type: LOADED });

      return response.data.data
 
    
  }catch (err) {
    if (err?.response?.status === 400||err?.response?.status === 409) {

      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
    } else if (err?.response?.status === 422) {
      err.response.data.errors.map(item => {
        dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
      });
    } else if (err?.response?.status === 404) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
    } else if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push('/login');
    } else if (err.message === 'Network Error') {
      history.push('/networkError');
    } else {
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
    }
    dispatch({ type: LOADED });
  }
};

export const updateRole = (data, roleId) => async (dispatch) => {
  dispatch({ type: LOADING });

  const obj = {
    uid:roleId,
    name: data.name,
    active: data.active,
    user_management: data.user_management ?'a': 'i',
    cms_management: data.cms_management ?'a': 'i',
    accounts_management: data.accounts_management
      ?'a': 'i',
    end_user_management: data.end_user_management ?'a': 'i',
  };
  try {
    const response = await api(localStorage.getItem("token")).put(
      "/role/update",
      { ...obj }
    );
    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Role is updated Successfully",
      });
      dispatch({ type: LOADED });
      history.push("/rolelist");
    } 
    
  }catch (err) {
    if (err?.response?.status === 400||err?.response?.status === 409) {

      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
    } else if (err?.response?.status === 422) {
      err.response.data.errors.map(item => {
        dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
      });
    } else if (err?.response?.status === 404) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
    } else if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push('/login');
    } else if (err.message === 'Network Error') {
      history.push('/networkError');
    } else {
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
    }
    dispatch({ type: LOADED });
  }
};

export const updateRoleAuth = (role, active) => async (dispatch) => {
  dispatch({ type: LOADING });

  const object = {
    uid: role,
    active: active,
  };
  dispatch({ type: LOADED });

  try {
    const response = await api(localStorage.getItem("token")).put(
      "/role/update/status",
      { ...object }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Role authentication is updated succesfully",
      });

      return response.status;
    }
  }catch (err) {
    if (err?.response?.status === 400||err?.response?.status === 409) {

      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
    } else if (err?.response?.status === 422) {
      err.response.data.errors.map(item => {
        dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
      });
    } else if (err?.response?.status === 404) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
    } else if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push('/login');
    } else if (err.message === 'Network Error') {
      history.push('/networkError');
    } else {
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
    }
    dispatch({ type: LOADED });
  }
};


  export const deleteRole = (id) => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const response = await api(localStorage.getItem("token")).delete(
        `/role/delete/${id}`
      );
      dispatch({ type: LOADED });
  
        if(response.status ===201){
          return response.status
        }
   
      
      }catch (err) {
        if (err?.response?.status === 400||err?.response?.status === 409) {
    
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
        } else if (err?.response?.status === 422) {
          err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
          });
        } else if (err?.response?.status === 404) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
        } else if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
        } else if (err.message === 'Network Error') {
          history.push('/networkError');
        } else {
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
        }
        dispatch({ type: LOADED });
      }
    };


    export const getRoleHelper = () => async (dispatch) => {
      dispatch({ type: LOADING });
      try {
        const response = await api(localStorage.getItem("token")).get(
          `role/helper`
        );
        dispatch({ type: LOADED });
        return response.data.data;
      }catch (err) {
        if (err?.response?.status === 400||err?.response?.status === 409) {
    
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
        } else if (err?.response?.status === 422) {
          err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
          });
        } else if (err?.response?.status === 404) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
        } else if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
        } else if (err.message === 'Network Error') {
          history.push('/networkError');
        } else {
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
        }
        dispatch({ type: LOADED });
      }
    };