import React from 'react'
import { Form, Input } from 'antd';

const InputBox = ({label,name, value, rules,placeholder, ...rest}) => {
    return ( 
       
        <Form.Item
            label={label}
            name={name}
            rules={rules}
           
            
            >
            <Input size="large" name={name} placeholder={placeholder} value={value} {...rest}/>
        </Form.Item>
     );
}
 
export default InputBox;