
import axios from "axios";
import history from "../history";
import {
  AUTH_ERROR,
  ERROR_MESSAGE,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  VALIDATION_ERROR,VALIDATION_AUTH_ERROR,
} from "../reducers/type";
import api from "./apis/index";



export const createSocialMediaContent = (data) => async (dispatch) => {
    dispatch({ type: LOADING });
  
    const object = {
      name: data.name||null,
      url_content: data.url_content,
      types: data.types||null,
      active: data.active,
      content_category: data.content_category||null,

  
    };
  
    try {
      const response = await api(localStorage.getItem("token")).post(
        "v1/social/admin/create",
        { ...object }
      );
  
      if (response.status === 201) {
        dispatch({ type: LOADED });
        dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
        return response.status;
      }
    }catch (err) {
      if (err?.response?.status === 400||err?.response?.status === 409) {
  
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
      } else if (err?.response?.status === 422) {
        err.response.data.errors.map(item => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
        });
      } else if (err?.response?.status === 404) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
      } else if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push('/login');
      } else if (err.message === 'Network Error') {
        history.push('/networkError');
      } else {
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
      }
      dispatch({ type: LOADED });
    }
  };
  
  export const getSocialMediaContent = () => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const response = await api(localStorage.getItem("token")).get(
        "v1/social/admin/all"
      );
  
      dispatch({ type: LOADED });
      return response.data.data;
    }catch (err) {
      if (err?.response?.status === 400||err?.response?.status === 409) {
  
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
      } else if (err?.response?.status === 422) {
        err.response.data.errors.map(item => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
        });
      } else if (err?.response?.status === 404) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
      } else if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push('/login');
      } else if (err.message === 'Network Error') {
        history.push('/networkError');
      } else {
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
      }
      dispatch({ type: LOADED });
    }
  };

  export const updateSocialMediaAuth = (user, auth) => async (dispatch) => {
    dispatch({ type: LOADING });
  
    const object = {
      uid: user,
      active: auth,
    };
    dispatch({ type: LOADED });
  
    try {
      const response = await api(localStorage.getItem("token")).put(
        "v1/social/admin/update/status",
        { ...object }
      );
  
      if (response.status === 201) {
        dispatch({ type: LOADED });
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: "Status is updated succesfully",
        });
  
        return response.status;
      }
    }catch (err) {
      if (err?.response?.status === 400||err?.response?.status === 409) {
  
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
      } else if (err?.response?.status === 422) {
        err.response.data.errors.map(item => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
        });
      } else if (err?.response?.status === 404) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
      } else if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push('/login');
      } else if (err.message === 'Network Error') {
        history.push('/networkError');
      } else {
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
      }
      dispatch({ type: LOADED });
    }
  };

  

  export const DeleteSocialMedia = (id) => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const response = await api(localStorage.getItem("token")).delete(
        `v1/social/admin/delete/${id}`
      );
      dispatch({ type: LOADED });
      return response.status;
    }catch (err) {
      if (err?.response?.status === 400||err?.response?.status === 409) {
  
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
      } else if (err?.response?.status === 422) {
        err.response.data.errors.map(item => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
        });
      } else if (err?.response?.status === 404) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
      } else if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push('/login');
      } else if (err.message === 'Network Error') {
        history.push('/networkError');
      } else {
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
      }
      dispatch({ type: LOADED });
    }
  };