import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { Statistic, Row, Col } from "antd";
import {
  LikeOutlined,
 
} from "@ant-design/icons";
import history from "../history";


const Dashboard = () => {
  const [access, setAccess] = useState('');
  
  useEffect(() => {
    window.scrollTo(0, 0)
    if(!localStorage.getItem("token") ||
    localStorage.getItem("token") === null){
      localStorage.clear();
      history.replace("/login");
    }
    setAccess(JSON.parse(localStorage.getItem('access')))
    // if(localStorage.getItem('token')){
      
    // }
    // else{
    //   localStorage.clear();
    //   history.push('/login')
    //   setAccess(JSON.parse(localStorage.getItem('access')))
    // }
  }, []);
  // console.log(access)
  return (
    <Content>
     
     <h1 style={{textAlign:'center'}}>Dashboard</h1>
      
    </Content>
  );
};

export default Dashboard;
