import React,{  useEffect, useState } from "react";
import { connect } from "react-redux";
import {Route } from "react-router-dom";
import Spinner from "../components/spinner";
import history from "../history";
//admin user and role
import CreateRole from "../pages/Admin_Management/Role/CreateRole";
import EditRole from "../pages/Admin_Management/Role/EditRole";
import RoleList from "../pages/Admin_Management/Role/RoleList";
import UserList from "../pages/Admin_Management/User/UserList";
import CreateUser from "../pages/Admin_Management/User/CreateUser";

//end user
import EndUserList from "../pages/Client_Managment/end_user/UserList";
import EndCreateUser from "../pages/Client_Managment/end_user/CreateUser";
import EndUserDetails from "../pages/Client_Managment/end_user/UserDetails";

//social list 
import SocialList from "../pages/Cms_Management/Social_Management/SocialList";


//cms list
import CmsList from "../pages/Cms_Management/cms/CmsList";
import CmsDetails from "../pages/Cms_Management/cms/CmsDetails";

//finance list
import FinanceList from "../pages/Accounts_Management/finance/FinanceList";
import FinanceDetails from "../pages/Accounts_Management/finance/FinanceDetails";



import notFound from "./notFound";
import UserDetails from "../pages/Admin_Management/User/UserDetails";
import NetworkError from "./NetworkError";
import Dashboard from "./dashboard";
import TypeForm from "./TypeForm";
import BannerList from "../pages/Cms_Management/banners/BannerList";
import BannerDetails from "../pages/Cms_Management/banners/BannerDetails";
import RssFeedList from "../pages/Cms_Management/rss_feed/RssFeedList";
import RssFeedDetails from "../pages/Cms_Management/rss_feed/RssFeedDetails";


const Switcher = ({loader}) => {
    const [access, setAccess] = useState(null)  
  useEffect(() => {
    window.scrollTo(0, 0)
    if(!localStorage.getItem("token") ||
    localStorage.getItem("token") === null){
      localStorage.clear();
      history.replace("/login");
    }
    setAccess(JSON.parse(localStorage.getItem('access')))
    
  }, [])
  return (
    <>
    {loader ? <Spinner /> : null} 
      <switch>
    
        <Route path="/" exact component={Dashboard}/>
        <Route path="/dashboard" exact component={Dashboard}/>
        <Route path="/notFound" exact component={notFound}/>
        <Route path="/networkError" exact component={NetworkError}/>

       
        {/* Role */}
        <>
        <Route path="/rolelist"  component={RoleList}/>
        <Route path="/create-role"  component={CreateRole}/>
        <Route path="/edit-role" component={EditRole}/>
        </>
        
        {/* User Management */}
        <>
        <Route path="/userlist"  component={UserList}/>
        <Route path="/user-details"  component={UserDetails}/>
        <Route path="/create-user"  component={CreateUser}/>
        {/* <Route path="/edit-user" component={EditUser}/> */}
         </>
        {/*End User Management */}
        <>
        <Route path="/clientlist"  component={EndUserList}/>
        <Route path="/client-details"  component={EndUserDetails}/>
        <Route path="/create-user"  component={EndCreateUser}/>
        {/* <Route path="/edit-user" component={EditUser}/> */}
          
        </>
        <>
        <Route path="/financelist"  component={FinanceList}/>
        <Route path="/finance-details"  component={FinanceDetails}/>
        {/* <Route path="/edit-user" component={EditUser}/> */}
          
        </>
        <>
        <Route path="/cmslist"  component={CmsList}/>
        <Route path="/cms-details"  component={CmsDetails}/>
        {/* <Route path="/edit-user" component={EditUser}/> */}
          
        </>
        <>
        <Route path="/banner-list"  component={BannerList}/>
        <Route path="/banner-details"  component={BannerDetails}/>
        {/* <Route path="/cms-details"  component={CmsDetails}/> */}
        {/* <Route path="/edit-user" component={EditUser}/> */}
          
        </>
        <>
        <Route path="/rss-feed-list"  component={RssFeedList}/>
        <Route path="/rss-feed-details"  component={RssFeedDetails}/>
        {/* <Route path="/cms-details"  component={CmsDetails}/> */}
        {/* <Route path="/edit-user" component={EditUser}/> */}
          
        </>
        <>
        <Route path="/social-list"  component={SocialList}/>
        <Route path="/typeform"  component={TypeForm}/>
        {/* <Route path="/cms-details"  component={CmsDetails}/> */}
        {/* <Route path="/edit-user" component={EditUser}/> */}
          
        </>
       
      </switch>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.loader.loading,
    success: state.message.success,
    error: state.message.error,
    validationError: state.message.validationError,
  };
};
export default connect(mapStateToProps)(Switcher);
