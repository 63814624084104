import {Form, Drawer,Button } from 'antd';
import React from 'react';
import { renderTextField } from './form';
const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };
const Remarks = ({placement,visible,fnc,id, delFnc, refresh, title, btn}) => {

  const [form] = Form.useForm();
    const onFinish = async (values) => {
        const code = await delFnc(id,values);
  
        form.resetFields();
       
        if(code === 201 ){
            refresh()
            fnc()
          }
};

const onFinishFailed = (errorInfo) => {
// console.log('Failed:', errorInfo);
};
    return ( 

        <Drawer
              title={title ? title :"Why you want to delete ?"}
              placement={placement}
              closable={false}
              onClose={fnc}
              visible={visible}
              key={placement}
              height={250}
            >
              <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            style={{alignSelf: 'center'}}
            requiredMark={false}
            form={form}
          >
              {renderTextField('remark', 'Remark',
              [{ required: true, message: 'Remark is required' },] )}
              <Form.Item {...tailLayout}> 
                <Button type="primary" htmlType="submit">
                  {btn?btn:'Delete'}
                </Button>
              </Form.Item>
              </Form>
            </Drawer>
     );
}

 
export default Remarks;