import React from "react";
import { Layout, Button } from "antd";
import styled from "styled-components";

const { Content } = Layout;

const SuccessContent = styled(Content)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const SuccessPage = () => {
  return (
    <SuccessContent>
      <div>
        <h1>Password Updated Successfully!</h1>
        <a href="https://clients.glue.pitetris.com/login">
          <Button type="primary">
            Continue
          </Button>
        </a>
      </div>
    </SuccessContent>
  );
};

export default SuccessPage;
