import axios from "axios";
import history from "../history";
import {
  AUTH_ERROR,
  ERROR_MESSAGE,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  VALIDATION_ERROR,VALIDATION_AUTH_ERROR,
} from "../reducers/type";
import api from "./apis/index";
import moment from "moment";

export const createEndUsers = (data) => async (dispatch) => {
    dispatch({ type: LOADING });
  
    const object = {
      name: data.name,
      email: data.email,
      phone: data.mobile_number,
      password: data.password,
      active: data.authenticated,

  
    };
  
    try {
      const response = await api(localStorage.getItem("token")).post(
        "v1/user/admin/create",
        { ...object }
      );
  
      if (response.status === 201) {
        dispatch({ type: LOADED });
        dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
        return response.status;
      }
    }catch (err) {
      if (err?.response?.status === 400||err?.response?.status === 409) {
  
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
      } else if (err?.response?.status === 422) {
        err.response.data.errors.map(item => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
        });
      } else if (err?.response?.status === 404) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
      } else if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push('/login');
      } else if (err.message === 'Network Error') {
        history.push('/networkError');
      } else {
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
      }
      dispatch({ type: LOADED });
    }
  };
  
  export const getEndUsers = () => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const response = await api(localStorage.getItem("token")).get(
        "v1/user/admin/all"
      );
  
      dispatch({ type: LOADED });
      return response.data.data;
    }catch (err) {
      if (err?.response?.status === 400||err?.response?.status === 409) {
  
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
      } else if (err?.response?.status === 422) {
        err.response.data.errors.map(item => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
        });
      } else if (err?.response?.status === 404) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
      } else if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push('/login');
      } else if (err.message === 'Network Error') {
        history.push('/networkError');
      } else {
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
      }
      dispatch({ type: LOADED });
    }
  };

  export const getEndUserDetails = (id) => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const response = await api(localStorage.getItem("token")).get(
        `v1/user/admin/${id}`
      );
  
      if (response.status === 201) {
        dispatch({ type: LOADED });
        return response.data.details;
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        err.response.data.errors.map((item) => {
          dispatch({
            type: VALIDATION_ERROR,
            payload: [{ message: item.message, path: item.path[0] }],
          });
        });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push("/login");
      }
      if (err.message == "Network Error") {
        history.push("/networkError");
        return;
      }
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response ? err.response.data.message : history.push("/opps"),
      });
      dispatch({ type: LOADED });
    }
  };
  
  export const getEndUsersById = (id) => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const response = await api(localStorage.getItem("token")).get(
        `v1/user/admin/${id}`
      );
      dispatch({ type: LOADED });
      return response.data.data;
    }catch (err) {
      if (err?.response?.status === 400||err?.response?.status === 409) {
  
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
      } else if (err?.response?.status === 422) {
        err.response.data.errors.map(item => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
        });
      } else if (err?.response?.status === 404) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
      } else if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push('/login');
      } else if (err.message === 'Network Error') {
        history.push('/networkError');
      } else {
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
      }
      dispatch({ type: LOADED });
    }
  };
  
  export const updateEndUser = (uId,data) => async (dispatch) => {
    dispatch({ type: LOADING });
  
    const object = {
      uid:uId,
      name: data.name,
      phone: data.phone,
  
    };
    dispatch({ type: LOADED });
  
    try {
      const response = await api(localStorage.getItem("token")).put(
        "v1/user/admin/update",
        { ...object }
      );
  
      if (response.status === 201) {
        dispatch({ type: LOADED });
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: "User is updated succesfully",
        });
  
        history.push("/clientlist");
      }
    }catch (err) {
      if (err?.response?.status === 400||err?.response?.status === 409) {
  
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
      } else if (err?.response?.status === 422) {
        err.response.data.errors.map(item => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
        });
      } else if (err?.response?.status === 404) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
      } else if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push('/login');
      } else if (err.message === 'Network Error') {
        history.push('/networkError');
      } else {
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
      }
      dispatch({ type: LOADED });
    }
  };
  
  export const updateEndUserPassword = (user, data) => async (dispatch) => {
    dispatch({ type: LOADING });
    const object = {
      uid: user,
      new_password: data.new_password,
    };
    dispatch({ type: LOADED });
  
    try {
      const response = await api(localStorage.getItem("token")).put(
        "v1/user/admin/change-password",
        { ...object }
      );
  
      if (response.status === 201) {
        dispatch({ type: LOADED });
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: "User Password is updated succesfully",
        });
        return response.status;
      }
    }catch (err) {
      if (err?.response?.status === 400||err?.response?.status === 409) {
  
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
      } else if (err?.response?.status === 422) {
        err.response.data.errors.map(item => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
        });
      } else if (err?.response?.status === 404) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
      } else if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push('/login');
      } else if (err.message === 'Network Error') {
        history.push('/networkError');
      } else {
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
      }
      dispatch({ type: LOADED });
    }
  };
  
  
    export const deleteEndUser = (id) => async (dispatch) => {
      dispatch({ type: LOADING });
      try {
        const response = await api(localStorage.getItem("token")).delete(
          `v1/user/admin/delete/${id}`
        );
        dispatch({ type: LOADED });
        return response.status;
      }catch (err) {
        if (err?.response?.status === 400||err?.response?.status === 409) {
    
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
        } else if (err?.response?.status === 422) {
          err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
          });
        } else if (err?.response?.status === 404) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
        } else if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
        } else if (err.message === 'Network Error') {
          history.push('/networkError');
        } else {
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
        }
        dispatch({ type: LOADED });
      }
    };
  
  export const updateEndUserAuth = (user, auth) => async (dispatch) => {
    dispatch({ type: LOADING });
  
    const object = {
      uid: user,
      active: auth,
    };
    dispatch({ type: LOADED });
  
    try {
      const response = await api(localStorage.getItem("token")).put(
        "v1/user/admin/update/status",
        { ...object }
      );
  
      if (response.status === 201) {
        dispatch({ type: LOADED });
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: "User authentication is updated succesfully",
        });
  
        return response.status;
      }
    }catch (err) {
      if (err?.response?.status === 400||err?.response?.status === 409) {
  
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
      } else if (err?.response?.status === 422) {
        err.response.data.errors.map(item => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
        });
      } else if (err?.response?.status === 404) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
      } else if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push('/login');
      } else if (err.message === 'Network Error') {
        history.push('/networkError');
      } else {
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
      }
      dispatch({ type: LOADED });
    }
  };
  export const updateEndUserPayment = (user, data) => async (dispatch) => {
    dispatch({ type: LOADING });
  
    const object = {
      uid: user,
      payment_status: data.payment_status,
      service: data.service,
      subscription: data.subscription,
      subscription_start_time: (moment(data.subscription_start_time).format('YYYY-MM-DDTHH:mm:ss')) ||'',
      subscription_end_time: (moment(data.subscription_end_time).format('YYYY-MM-DDTHH:mm:ss'))||'',
      amount_paid: data.amount_paid? data.amount_paid:0,
      amount_due: data.amount_due?data.amount_due:0,
    };
    dispatch({ type: LOADED });
  
    try {
      const response = await api(localStorage.getItem("token")).put(
        "v1/user/admin/update/payment",
        { ...object }
      );
  
      if (response.status === 201) {
        dispatch({ type: LOADED });
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: "User payment is updated succesfully",
        });
  
        return response.status;
      }
    }catch (err) {
      if (err?.response?.status === 400||err?.response?.status === 409) {
  
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
      } else if (err?.response?.status === 422) {
        err.response.data.errors.map(item => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
        });
      } else if (err?.response?.status === 404) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
      } else if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push('/login');
      } else if (err.message === 'Network Error') {
        history.push('/networkError');
      } else {
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
      }
      dispatch({ type: LOADED });
    }
  };
  