import {
  CheckCircleOutlined,
  EditOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  Button,
  Descriptions,
  Divider,DatePicker,
  Empty,
  Form,
  Modal,
  PageHeader,
  Popconfirm,
  Tag,Select ,
  Timeline,Alert, Space,
  Row,
  Col
} from "antd";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ReactPlayer from "react-player";
import {

  getBannerDetailsAdmin
  
} from "../../../actions/banner";
import {
  renderDate,
  renderInput,
  renderSelect,
  renderTextField,
} from "../../../components/form";

const { Option } = Select;
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};
const payment_option = [
  { value: "completed", label: "Completed" },
  { value: "pending", label: "Pending" },
  { value: "due", label: "Due" },
  { value: "failed", label: "Failed" },
  { value: "cancel", label: "Cancel" },
  { value: "", label: "None" },
];
// const payment_option = [
//   { value: "completed", label: "Completed" },
//   { value: "pending", label: "Pending" },
//   { value: "receive", label: "Receive" },
//   { value: "pogress", label: "Pogress" },
//   { value: "edit", label: "Edit" },
//   { value: "revision", label: "Revision" },
//   { value: "revision_in_pogress", label: "Revision In Pogress" },
//   { value: "revision_in_completed", label: "Revision In Completed" },
//   { value: "", label: "None" },
// ];
const service_option = [
  { value: "platinum", label: "Platinum" },
  { value: "gold", label: "Gold" },
  { value: "silver", label: "Silver" },
  { value: "bronze", label: "Bronze" },
  { value: "", label: "None" },
];
const subscription_option = [
  { value: "hourly", label: "Hourly" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "yearly", label: "Yearly" },
  { value: "", label: "None" },
];
const status = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];
const Wrapper = styled.div`
  padding: 20px;
`;
const Title = styled.div`
  width: 100%;
  background-color: #020202;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 5px;
  text-align: center;
`;
const BannerDetails = ({
  location,

  getBannerDetailsAdmin
}) => {

  const [data, setData] = useState([]);
 

  useEffect(async () => {
    window.scrollTo(0, 0);
    const id = new URLSearchParams(location.search).get("banner_id");
    const res = await getBannerDetailsAdmin(id);
    setData(res);
    console.log(res,'fffffd')
    setData(res);
  }, []);

  const pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Banner Details"
      subTitle="CMS Management"
      
    ></PageHeader>
  );

  const detailscms = (
    <>
   
      <Wrapper>
        {data ? (
          <>
            <Title>Banner Information</Title>
            <br />
            <Descriptions>
              <Descriptions.Item label="Name">
                <strong>{data?.name||"None"}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Status">
                <strong>{data?.active===true?"Active":"Inactive"}</strong>
              </Descriptions.Item>
              
              
            </Descriptions>
          </>
        ) : null}

           
            <Title>Content Show</Title>
            <br></br>
            <Row gutter={[16, 16]}> {/* gutter is the space between columns */}
              {/* <Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}> */}
              
                <img src={`https://api.glue.pitetris.com/margaret/v1/banner/show/no/${data?.uid}`} alt="Image 1" style={{width: '100%' }} />
              {/* </Col> */}
              </Row>
      </Wrapper>
    </>
  );




  return (
    <Content>
      {pageheader}
      {detailscms}


    </Content>
  );
};

export default connect(null, {
  getBannerDetailsAdmin,
  
})(BannerDetails);
