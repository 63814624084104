
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import history from "../../../history";
import {
  getEndUsers,
  updateEndUser,
  deleteEndUser,
  createEndUsers,
  updateEndUserPassword,
  updateEndUserAuth,
} from "../../../actions/end_user";
import {
  Table,
  Input,
  Button,
  PageHeader,
  Popconfirm,
  Breadcrumb,
  Tag,Image
} from "antd";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusCircleFilled,
  ReadOutlined,
  SearchOutlined,
  SettingOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import Remarks from "../../../components/remark";
import CreateUser from "./CreateUser";
import ChangePass from "./Changepass";


const UserList = ({
  getEndUsers,
  updateEndUser,
  deleteEndUser,
  createEndUsers,
  updateEndUserPassword,
  updateEndUserAuth,
}) => {
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [palacement, setPlacement] = useState("bottom");
  const [passVisible, setPassVisible] = useState(false);
  const [createUser, setCreateUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [id, setId] = useState("");

  useEffect(async () => {
    window.scrollTo(0, 0)
    const res = await getEndUsers();
    setData(res);
  }, []);

  const Refresh = async () => {
    const res = await getEndUsers();
    setData(res);
  };

  const handlePassVisible = (id) => {
    setId(id);
    setPassVisible(true);

  };
  const handleVisible=async(id)=>{
    history.push(`/client-details?client=${id}`);
  }
  const handleEditModalVisible = (id) => {
   
    setId(id);
    setEditUser(true);
    
  };

  const createuser = () => {
    setCreateUser(true);
  };
  const showDrawer = (id) => {

    setId(id);
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
    setPassVisible(false);
    setEditUser(false);
    
  };
  const handleDownloadClick = async (uid, name,extension,i) => {
    try {
      const response = await fetch(`https://api.glue.pitetris.com/margaret/v1/content/admin/download/${uid}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
          // Add other headers if required
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to download file');
      }
  
      const totalSize = response.headers.get('Content-Length');
      let downloadedSize = 0;
      const reader = response.body.getReader();
      const chunks = [];
  
      while (true) {
        const { done, value } = await reader.read();
  
        if (done) {
          break;
        }
  
        chunks.push(value);
        downloadedSize += value.length;
  
        const progress = Math.round((downloadedSize / totalSize) * 100);
        // console.log(`Downloaded: ${progress}%`);
        setData((prevData) => {
          const newData = [...prevData];
          newData[i].downloadProgress = progress;
          return newData;
        });
        // Update UI with progress (example: set state, display progress bar, etc.)
        // For simplicity, let's log it to console
      }
  
      const blob = new Blob(chunks);
      const url = window.URL.createObjectURL(blob);
  
      const a = document.createElement('a');
      a.href = url;
  
      // Use a default filename
      a.download = `${uid}_${name}.${extension}`;
  
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };
  const handleRefresh = async () => {
    const res = await getEndUsers();
    setData(res);
  };
  const handleStatus = async (role, active) => {
    const code = await updateEndUserAuth(role, active);
    if (code === 201) {
      const res = await getEndUsers();
      setData(res);
    }
  };

  const searchColumns = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          ></Input>
          <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            type="danger"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });
  const columns = [
    {
      title: "Profile",
      dataIndex: "id",
      key: "id",
      width: "10%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "20%",
      ...searchColumns("email"),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "15%",
      ...searchColumns("name"),
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: "15%",
      ...searchColumns("mobile_number"),
    },
    {
      title: "Status",
      dataIndex: "authenticated",
      key: "authenticated",
      width: "20%",
      ...searchColumns("authenticated"),
    },
    // {
    //   title: "Payment Status",
    //   dataIndex: "payment_status",
    //   key: "payment_status",
    //   width: "10%",
    //   ...searchColumns("payment_status"),
    // },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "20%",
    },
  ];
  const createData = (id,
    email,
    name,
    mobile_number,
    authenticated,
    action
  ) => {
    return { id,email, name, mobile_number, authenticated, action };
  };

  const rows = [
    data
      ? data?.map((user,i) =>
          createData(
            <>
            <Image
                  width="80%"
                  src={`https://api.glue.pitetris.com/margaret/v1/user/profile/show/no/${user?.uid}`}
                  style={{ border: "none", borderRadius:'50%'}}
                />   
            </> ,
            user?.email,
            user.name,
            user.phone,
            <>
              {user?.active ? (
                <>
                  <Tag color="#87d068">Active</Tag>
                  <Popconfirm
                    title="Are you sure？"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => handleStatus(user?.uid, false)}
                  >
                    <Button size="small" type="danger" icon={<StopOutlined />}>
                      Disable
                    </Button>
                  </Popconfirm>
                </>
              ) : (
                <>
                  <Tag color="#f50">Inactive</Tag>
                  <Popconfirm
                    title="Are you sure？"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => handleStatus(user?.uid, true)}
                  >
                    <Button
                      size="small"
                      type="primary"
                      icon={<CheckCircleOutlined />}
                    >
                      Activate
                    </Button>
                  </Popconfirm>
                </>
              )}
            </>,
            // user?.payment_status?user.payment_status:"None",

            <>
              {/* <Button
                size="small"
                type="primary"
                icon={<EditOutlined />}
                onClick={() => handleEditModalVisible(user?.user_id)}
              >
                Edit
              </Button> */}
              <Button
                size="small"
                type="primary"
                icon={<EyeOutlined />}
                onClick={() => {handleVisible(user?.uid)}}
              >
                View
              </Button>
              &nbsp;
              <Popconfirm
                title="Are you sure？"
                okText="Yes"
                cancelText="No"
                onConfirm={() => showDrawer(user?.uid)}
              >
                <Button size="small" type="danger" icon={<DeleteOutlined />}>
                  Delete
                </Button>
              </Popconfirm>
              <br />
              <br />
              <Button
                size="small"
                type="primary"
                icon={<SettingOutlined />}
                onClick={() => handlePassVisible(user.uid)}
              >
                Change Password
              </Button>
              &nbsp;
            </>
          )
        )
      : "",
  ];

  const Pageheader = (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Client List"
        subTitle="Clients Management"
        extra={[
          <>
            <Button
              key="1"
              type="primary"
              icon={<PlusCircleFilled />}
              onClick={() => createuser()}
            >
              Create New
            </Button>
            &nbsp;
            
          </>,
        ]}
      ></PageHeader>
{createUser? 
      <CreateUser
        title="Create Clients"
        visible={createUser}
        cancel={() => setCreateUser(false)}
        ok={() => setCreateUser(false)}
        api={createEndUsers}
        refresh={handleRefresh}
      />:null}
    </>
  );
  const RemarksDelete = (
    <Remarks
      placement={palacement}
      visible={visible}
      fnc={onClose}
      id={id}
      delFnc={deleteEndUser}
      data={data}
      refresh={handleRefresh}
    />
  );

  const changepass = (
    <ChangePass
      placement="right"
      visible={passVisible}
      fnc={onClose}
      id={id}
      passFnc={updateEndUserPassword}
      data={data}
      refresh={handleRefresh}
    />
  );
 
  return (
    <Content>
      {Pageheader}
      <Table
        columns={columns}
        dataSource={rows[0]}
        scroll={{ x: 1000 }}
        sticky
      />
      {RemarksDelete}
      {changepass}
      {/* {EditUsers} */}
    </Content>
  );
};

export default connect(null, {
  getEndUsers,
  updateEndUser,
  deleteEndUser,
  createEndUsers,
  updateEndUserPassword,
  updateEndUserAuth,
})(UserList);