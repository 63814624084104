
import React, { useState } from "react";
import { Form, Input, Button, Layout, Col, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import styled from "styled-components";
import Logo from "./B1.png";
import { EndUserForgetPassword } from "../actions/auth";
import { connect } from "react-redux";
const { Footer } = Layout;

const Root = styled.div`
  position: flex;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Card = styled.div`
  position: reletive;
  box-shadow: -2px 6px 17px 2px rgba(0, 0, 0, 0.37);
  transition: 0.3s;
  width: 40%;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
const ForgetPassword = ({ location, EndUserForgetPassword }) => {
  const [form] = Form.useForm();
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [password, setPassword] = useState('');
  const email = new URLSearchParams(location.search).get("email");

  const onFinish = (values) => {
    console.log('values', values)
    console.log('password', password)
    if (!email || !password) {
      message.error("Email and password are required.");
      return;
    }
    EndUserForgetPassword(email, password);
  };

  const handleConfirmBlur = (e) => {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  };

  const compareToFirstPassword = (_, value) => {
    if (value && value !== form.getFieldValue("password")) {
      return Promise.reject(new Error("The two passwords do not match"));
    }
    return Promise.resolve();
  };

  const validateToNextPassword = (_, value) => {
    if (value && confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    return Promise.resolve();
  };

  return (
    <Layout
      style={{ minHeight: "100vh", display: "flex", justifyContent: "center" }}
    >
      <Root>
        <Card>
          <Col
            span={24}
            style={{
              alignSelf: "center",
              borderRadius: "10px",
              height: "500px",
              backgroundColor: "#fff",
              padding: "20px",
              display: "flex",
              justifyContent: "space-around",
              flexDirection: "column",
            }}
          >
            <img
              src={Logo}
              alt="glue"
              style={{ width: "100px", alignSelf: "center" }}
            />
            <h2 style={{ textAlign: "center" }}>Reset Password</h2>
            <Form
              form={form}
              style={{
                alignSelf: "center",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
              name="normal_login"
              className="login-form"
              initialValues={{
                remember: true,
              }}
              // onFinish={onFinish}
            >
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Field is required",
                  },
                  {
                    validator: validateToNextPassword,
                  },
                ]}
                style={{ alignSelf: "center", width: "100%" }}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="New Password"
                  size="large"
                  
                />
              </Form.Item>
              <Form.Item
                name="confirm"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Password is required",
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                ]}
                hasFeedback
                style={{ alignSelf: "center", width: "100%" }}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Confirm Password"
                  size="large"
                  onBlur={handleConfirmBlur}
                  onChange={(e)=>{setPassword(e.target.value)}}
                />
              </Form.Item>

      
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  onClick={() => { onFinish(password)} }
                >
                  Reset Password
                </Button>
              
            </Form>
          </Col>
        </Card>
      </Root>
      <Footer style={{ textAlign: "center", height: "10vh" }}>
        <Footer style={{ textAlign: "center" }}>
          Copyright © 2024-2025 pi Tetris. All rights reserved.
        </Footer>
      </Footer>
    </Layout>
  );
};

export default connect(null, { EndUserForgetPassword })(ForgetPassword);
