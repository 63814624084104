import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import history from "../../../history";
import {
  getCmsListAdmin,getUploadAdmin,getUploadAdmindata,deleteEditetContent,deleteEditetContentMain
} from "../../../actions/cms";
import {
  Table,
  Input,
  Button,
  PageHeader,
  Progress,
  Breadcrumb,  Image,Form,Modal,Select,
  Tag,
  Upload,
  Popconfirm,
} from "antd";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  DownloadOutlined,
  ReadOutlined,
  SearchOutlined,
  SettingOutlined,
  StopOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { message } from "antd";
import { Content } from "antd/lib/layout/layout";
import Remarks from "../../../components/remark";
import {
  renderDate,
  renderInput,
  renderSelect,
  renderTextField,
} from "../../../components/form";
import ReactPlayer from "react-player";
const { Option } = Select;


const CmsList = ({
  getCmsListAdmin,getUploadAdmin,deleteEditetContent,deleteEditetContentMain
}) => {
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [deliveryStatus, setDeliveryStatus] = useState(false);
  const [contentypes, setContentTypes] = useState("");
  const [uploadtypes, setUploadType] = useState("");
  const [content, setContent] = useState("");
  const [passVisible, setPassVisible] = useState(false);
  const [createUser, setCreateUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [id, setId] = useState("");
  // const [downloadProgress, setDownloadProgress] = useState(0);
  const [form] = Form.useForm();

  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };
  useEffect(async () => {
    window.scrollTo(0, 0)
    const res = await getCmsListAdmin();
    setData(res);
  }, []);
  const onFinish = async (values) => {
    const code = await getUploadAdmin(id,content,values);
    if (code==201) {
      handleEditModalVisible();
      setVisibleEdit(false);
      handleRefresh()
      // form.resetFields();
    }
  };
 
  const handleVisible=async(id)=>{
    history.push(`/cms-details?cms_id=${id}`);
  }
  const handleUploadClick=async(id)=>{
    setId(id);
    console.log(id);
    setVisibleEdit(true)
  }
  const handleEditModalVisible = (id) => {
   
    setId(id);
    setEditUser(true);
    
  };


  const DeleteEditContent = async(id) => {
    const code=await deleteEditetContent(id)
    if (code===201){
      message.success("Edit Content Delete Succesfully");
      handleRefresh()
    }
  };
  const DeleteEditContentMain = async(id) => {
    const code=await deleteEditetContentMain(id)
    if (code===201){
      message.success("Content Delete Succesfully");
      handleRefresh()
    }
  };
  const handleDownloadClick = async (uid, name,extension,i) => {
    try {
      const response = await fetch(`https://api.glue.pitetris.com/margaret/v1/content/admin/download/${uid}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
          // Add other headers if required
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to download file');
      }
  
      const totalSize = response.headers.get('Content-Length');
      let downloadedSize = 0;
      const reader = response.body.getReader();
      const chunks = [];
  
      while (true) {
        const { done, value } = await reader.read();
  
        if (done) {
          break;
        }
  
        chunks.push(value);
        downloadedSize += value.length;
  
        const progress = Math.round((downloadedSize / totalSize) * 100);
        // console.log(`Downloaded: ${progress}%`);
        setData((prevData) => {
          const newData = [...prevData];
          newData[i].downloadProgress = progress;
          return newData;
        });
        // Update UI with progress (example: set state, display progress bar, etc.)
        // For simplicity, let's log it to console
      }
  
      const blob = new Blob(chunks);
      const url = window.URL.createObjectURL(blob);
  
      const a = document.createElement('a');
      a.href = url;
  
      // Use a default filename
      a.download = `${uid}_${name}.${extension}`;
  
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const handleDownloadEditetClick = async (uid, name,extension,i) => {
    try {
      const response = await fetch(`https://api.glue.pitetris.com/margaret/v1/content/admin/edited-download/${uid}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
          // Add other headers if required
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to download file');
      }
  
      const totalSize = response.headers.get('Content-Length');
      let downloadedSize = 0;
      const reader = response.body.getReader();
      const chunks = [];
  
      while (true) {
        const { done, value } = await reader.read();
  
        if (done) {
          break;
        }
  
        chunks.push(value);
        downloadedSize += value.length;
  
        const progress = Math.round((downloadedSize / totalSize) * 100);
        // console.log(`Downloaded: ${progress}%`);
        setData((prevData) => {
          const newData = [...prevData];
          newData[i].downloadProgressEdit = progress;
          return newData;
        });
        // Update UI with progress (example: set state, display progress bar, etc.)
        // For simplicity, let's log it to console
      }
  
      const blob = new Blob(chunks);
      const url = window.URL.createObjectURL(blob);
  
      const a = document.createElement('a');
      a.href = url;
  
      // Use a default filename
      a.download = `${uid}_${name}.${extension}`;
  
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };
  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };
  const onClose = () => {
    setVisible(false);
    setPassVisible(false);
    setEditUser(false);
    
  };

  const handleRefresh = async () => {
    const res = await getCmsListAdmin();
    setData(res);
  };
  const handlePassVisible = (id) => {
    setId(id);
    setPassVisible(true);

  };
  const searchColumns = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          ></Input>
          <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            type="danger"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: "5%",
    },
    {
      title: "UID",
      dataIndex: "uid",
      key: "uid",
      width: "15%",
      ...searchColumns("uid"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "15%",
      ...searchColumns("email"),
    },
    {
      title: "Raw Content",
      dataIndex: "img",
      key: "img",
      width: "15%",
      ...searchColumns("img"),
    },
    {
      title: "Edited Content",
      dataIndex: "e_img",
      key: "e_img",
      width: "15%",
      ...searchColumns("e_img"),
    },
    {
      title: "Upload Content",
      dataIndex: "u_img",
      key: "u_img",
      width: "15%",
      ...searchColumns("u_img"),
    },
    // {
    //   title: "Delivery Status",
    //   dataIndex: "delivery_status",
    //   key: "delivery_status",
    //   width: "15%",
    //   ...searchColumns("delivery_status"),
    // },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "15%",
    },
  ];
  const createData = (id,
    uid,email,
    img,
    upload_medium,
    e_img,
    u_img,
    action,
  ) => {
    return { id,email,uid,img, upload_medium, e_img,u_img, action };
  };
  
  const handleFileChange = (event) => {
    // let blob = new Blob([e.target.value], {type: 'ePub'});
  console.log(event.target.files[0])
  setContent(event.target.files[0])
    // if ((event.target.files[0]?.name).split(".")[1] === "png") {
    //   if (event.target.name === "addfiles") {
    //     setAddFiles(event.target.files[0]);
    //   } else {
    //     setAdThumbnail(event.target.files[0]);
    //   }
    // } else if ((event.target.files[0]?.name).split(".")[1] === "mp4") {
    //   if (event.target.name === "addvideofiles") {
    //     if (event.target.files[0]?.size > 120586240) {
    //       alert("File size exceeds the maximum limit (100mb)");
    //       form.setFieldsValue({
    //         addvideofiles: "",
    //       });
    //       return;
    //     }
    //     setAddVideoFiles(event.target.files[0]);
    //   }
    //   if (event.target.name === "thumnil") {
    //     form.setFieldsValue({
    //       thumnil: "",
    //     });
    //     alert("Invalid file format or file name. Accept only png");
    //   }
    //   if (event.target.name === "addfiles") {
    //     form.setFieldsValue({
    //       addfiles: "",
    //     });
    //     alert("Invalid file format or file name. Accept only png");
    //   }
    // } else {
    //   if (event.target.name === "addfiles") {
    //     form.setFieldsValue({
    //       addfiles: "",
    //     });
    //     alert("Invalid file format or file name. Accept only png");
    //   }
    //   if (event.target.name === "addvideofiles") {
    //     form.setFieldsValue({
    //       addvideofiles: "",
    //     });
    //     alert("Invalid file format or file name. Accept only mp4");
    //   }
    //   if (event.target.name === "thumnil") {
    //     form.setFieldsValue({
    //       thumnil: "",
    //     });
    //     alert("Invalid file format or file name. Accept only png");
    //   }
    //   return;
    // }
  };

 
  const UploadContent = (
    <>
      <Modal
        title="Content Upload by Admin"
        centered
        visible={visibleEdit}
        cancelText="Close"
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setVisibleEdit(false)}
        width={800}
      >
        {data ? (
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            style={{ alignSelf: "center" }}
            requiredMark={false}
            form={form}
          >
            
            {/* {renderInput('user_id', 'User ID', [
                    { required: true, message: 'This field is required!' },
                  ])
                } */}
            {renderInput("name", "Content Name", [
              { required: true, message: "This field is required!" },
            ])}
            
            <Form.Item
          name="admin_type"
          label="Content Type"
          rules={[{ required: true, message: "Field type is required" }]}
        >
          <Select showSearch placeholder="Select One" size="large"  onChange={(e) => {
            setContentTypes(e);
          }}>
            
            <Option value='image' >Image</Option>
            <Option value='video' >Video</Option>
            <Option value='another' >Another</Option>
            <Option value=" ">None</Option>
            
          </Select>
        </Form.Item>
            {/* <Form.Item
          name="delivery_status"
          label="Delivery Status"
          rules={[{ required: true, message: "Field type is required" }]}
        >
          <Select showSearch placeholder="Select One" size="large"  onChange={(e) => {
            setDeliveryStatus(e);
          }}>
            
            <Option value='pending' >Pending</Option>
            <Option value='complete' >Complete</Option>
            <Option value='receive' >Receive</Option>
            <Option value='pogress' >Pogress</Option>
            <Option value='edit' >Edit</Option>
            <Option value='revision' >Revision</Option>
            <Option value='revision_in_pogress' >Revision In Pogress</Option>
            <Option value='revision_in_completed' >Revision In Completed</Option>
            <Option value=" " >None</Option>
            
          </Select>
        </Form.Item> */}
          <Form.Item
            name="edit_upload_medium"
            label="Upload Type"
            rules={[{ required: true, message: "Field type is required" }]}
          >
          <Select showSearch placeholder="Select One" size="large"  onChange={(e) => {
            setUploadType(e);
          }}>
            
            <Option value='upload' >Upload</Option>
            <Option value='drive' >Drive</Option>
            <Option value='s3_bucket' >S3 Bucket</Option>
          </Select>
        </Form.Item>
        {
          uploadtypes==="upload" ?
          <Form.Item
          label={"Upload"}
          name="upload_file"
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Input
            type="file"
            name="upload_file"
            onChange={(e) => handleFileChange(e)}
          />
        </Form.Item>:uploadtypes==="drive"?
        <> 
        {renderInput("edit_upload_drive_link", "Drive Link", [
          { required: true, message: "This field is required!" },
        ])}
        </>
            :null
        }
            
        
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Modal>
    </>
  ); 
  
  const rows = [
    data
      ? data?.map((user,i) =>
          createData(
            i+1,
            user?.uid,
            user?.email,
            user.upload_medium==="upload" && user.types=="image"?
            <>
            <Image
                  width="50%"
                  src={`https://api.glue.pitetris.com/margaret/v1/content/show/no/${user?.uid}`}
                  style={{ border: "none", }}
                />
                <br />
                <Button onClick={() => handleDownloadClick(user?.uid,user?.name,user?.extension,i)} 
                style={{
                border: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor:'#f4fcf9',
                fontSize: "30px",
                fontWeight: "600"
              }} >
                <DownloadOutlined /></Button>
                {user.downloadProgress>1?
                <Progress  percent={user.downloadProgress || 0} />:null}
            </>:
             user.upload_medium==="upload" && user.types=="video"?
             <>
             <ReactPlayer
             // onEnded={onChange}
             url={`https://api.glue.pitetris.com/margaret/v1/content/show/no/${user?.uid}`}
             muted={true}
             playing={true}
             width="448px" // Set the width to 400 pixels (adjust as needed)
             height="250px" // Set the height to 225 pixels (adjust as needed)
           />
           <Button onClick={() => handleDownloadClick(user?.uid,user?.name,user?.extension,i)}  style={{
                border: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor:'#f4fcf9',
                fontSize: "30px",
                fontWeight: "600"
              }} >
                <DownloadOutlined /></Button>
                {user.downloadProgress>1?
                  <Progress percent={user.downloadProgress || 0}/>:null}
           </>
            :
            <>
            <Button onClick={() => handleDownloadClick(user?.uid,user?.name,user?.extension,i)}  style={{
                border: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor:'#f4fcf9',
                fontSize: "30px",
                fontWeight: "600"
              }} >
                <DownloadOutlined /></Button>
                {user.downloadProgress>1?
                  <Progress percent={user.downloadProgress || 0}/>:null}
            </>
                
            ,
            user.upload_medium,
            user.edit_upload_medium==="upload" && user.admin_types=="image"?
            <>
                <Image
                  width="50%"
                  src={`https://api.glue.pitetris.com/margaret/v1/content/edit/show/no/${user?.uid}`}
                  style={{ border: "none" }}
                />
                
            </>
            : user.edit_upload_medium==="upload" && user.admin_types=="video"?
            <>
             <ReactPlayer
             // onEnded={onChange}
             url={`https://api.glue.pitetris.com/margaret/v1/content/edit/show/no/${user?.uid}`}
             muted={true}
             playing={true}
             width="60px" // Set the width to 400 pixels (adjust as needed)
             height="60px" // Set the height to 225 pixels (adjust as needed)
           />
          
            </>
            
            :user.edit_upload_status===null && user.admin_types==null ||user.edit_upload_status===false && user.admin_types==null?
            <>
              <Image
              width="50%"
              src={`https://api.glue.pitetris.com/margaret/v1/user/logo/no_data.png`}
              style={{ border: "none" }}
              />
              <br />
      
             
            </>
      :<>
      <br />
      <Button onClick={() => handleDownloadEditetClick(user?.uid,user?.name,user?.extension_admin,i)} 
      style={{
      border: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor:'#f4fcf9',
      fontSize: "30px",
      fontWeight: "600"
    }} >
      <DownloadOutlined /></Button>
      {user.downloadProgressEdit>1?
      <Progress  percent={user.downloadProgressEdit || 0} />:null}</>,
      
      <>
      {user.edit_upload_status!==true?
        <Button icon={<UploadOutlined />}  onClick={() => handleUploadClick(user?.uid,)}>Upload</Button>:<h1>Content Uploaded</h1>
      }
      
 
      </>,
            // user?.delivery_status?user.delivery_status:"None",

            <>
              {/* <Button
                size="small"
                type="primary"
                icon={<EditOutlined />}
                onClick={() => handleEditModalVisible(user?.user_id)}
              >
                Edit
              </Button> */}
              <Button
                size="small"
                type="primary"
                icon={<EyeOutlined />}
                onClick={() => {handleVisible(user?.uid)}}
              >
                View
              </Button>
              &nbsp;
              <Popconfirm
                title="Are you sure to Delete Edited Content？"
                okText="Yes"
                cancelText="No"
                onConfirm={() => DeleteEditContent(user?.uid)}
              >
                <Button size="small" type="danger" icon={<DeleteOutlined />}>
                EC Delete
                </Button>
              </Popconfirm>
              <br />
             
              <Popconfirm
                title="Are you sure to Delete Main Content？"
                okText="Yes"
                cancelText="No"
                onConfirm={() => DeleteEditContentMain(user?.uid)}
              >
                <Button size="small" type="danger" icon={<DeleteOutlined />}>
                Delete File
                </Button>
              </Popconfirm>
              <br />

            </>
          )
        )
      : "",
  ];

  const Pageheader = (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="CMS List"
        subTitle="CMS Management"
        
      ></PageHeader>

    </>
  );
 
  return (
    <Content>
      {Pageheader}
      <Table
        columns={columns}
        dataSource={rows[0]}
        scroll={{ x: 1000 }}
        sticky
      />
      {UploadContent}
      
    </Content>
  );
};

export default connect(null, {
  getCmsListAdmin,getUploadAdmin,getUploadAdmindata,deleteEditetContent,deleteEditetContentMain
})(CmsList);



// const blob = await response.blob(); // Get the response body as a Blob
  
      // // No need to read the response body again since we already have it as a Blob
  
      // const url = window.URL.createObjectURL(blob);
  
      // const a = document.createElement('a');
      // a.href = url;
  
      // // Extract filename from Content-Disposition header if present
      // const contentDisposition = response.headers.get('Content-Disposition');
      // if (contentDisposition) {
      //   a.download = contentDisposition.split('=')[1];
      // } else {
      //   // Use a default filename if Content-Disposition is not provided
      //   a.download = `${uid}_${name}.${blob.type.split('/')[1]}`;
      // }
  
      // document.body.appendChild(a);
      // a.click();
      // window.URL.revokeObjectURL(url);