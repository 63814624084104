import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import history from "../../../history";
import {
  getUsers,
  updateAuth,
  deleteUser,
  createUsers,
  updateUserPassword,
  updateUser,
} from "../../../actions/user";
import {
  Table,
  Input,
  Button,
  PageHeader,
  Popconfirm,
  Breadcrumb,
  Tag,
} from "antd";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusCircleFilled,
  ReadOutlined,
  SearchOutlined,
  SettingOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";



const FinanceList = ({
  getUsers,
  updateAuth,
  deleteUser,
  createUsers,
  updateUserPassword,
  updateUser,
}) => {
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [palacement, setPlacement] = useState("bottom");
  const [passVisible, setPassVisible] = useState(false);
  const [createUser, setCreateUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [id, setId] = useState("");

  useEffect(async () => {
    window.scrollTo(0, 0)
    const res = await getUsers();
    setData(res);
  }, []);

  const Refresh = async () => {
    const res = await getUsers();
    setData(res);
  };

  const handlePassVisible = (id) => {
    setId(id);
    setPassVisible(true);

  };
  const handleVisible=async(id)=>{
    history.push(`/user-details?user=${id}`);
  }
  const handleEditModalVisible = (id) => {
   
    setId(id);
    setEditUser(true);
    
  };

  const createuser = () => {
    setCreateUser(true);
  };
  const showDrawer = (id) => {

    setId(id);
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
    setPassVisible(false);
    setEditUser(false);
    
  };

  const handleRefresh = async () => {
    const res = await getUsers();
    setData(res);
  };
  const handleStatus = async (role, active) => {
    const code = await updateAuth(role, active);
    if (code === 201) {
      const res = await getUsers();
      setData(res);
    }
  };

  const searchColumns = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          ></Input>
          <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            type="danger"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });
  const columns = [
    {
      title: "User ID",
      dataIndex: "user_id",
      key: "user_id",
      width: "10%",
      ...searchColumns("user_id"),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "15%",
      ...searchColumns("name"),
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: "15%",
      ...searchColumns("mobile_number"),
    },
    {
      title: "Status",
      dataIndex: "authenticated",
      key: "authenticated",
      width: "20%",
      ...searchColumns("authenticated"),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: "10%",
      ...searchColumns("role"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "20%",
    },
  ];
  const createData = (
    user_id,
    name,
    mobile_number,
    authenticated,
    role,
    action
  ) => {
    return { user_id, name, mobile_number, authenticated, role, action };
  };

  const rows = [
    data
      ? data?.map((user) =>
          createData(
            user?.user_id,
            user.name,
            user.mobile_number,
            <>
              {user?.active ? (
                <>
                  <Tag color="#87d068">Active</Tag>
                  <Popconfirm
                    title="Are you sure？"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => handleStatus(user?.uid, false)}
                  >
                    <Button size="small" type="danger" icon={<StopOutlined />}>
                      Disable
                    </Button>
                  </Popconfirm>
                </>
              ) : (
                <>
                  <Tag color="#f50">Inactive</Tag>
                  <Popconfirm
                    title="Are you sure？"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => handleStatus(user?.uid, true)}
                  >
                    <Button
                      size="small"
                      type="primary"
                      icon={<CheckCircleOutlined />}
                    >
                      Activate
                    </Button>
                  </Popconfirm>
                </>
              )}
            </>,
            user?.role_name==="" ?<spain  style={{ color: 'red',fontWeight:'600' }}>unassign</spain>: user?.role_name,

            <>
              {/* <Button
                size="small"
                type="primary"
                icon={<EditOutlined />}
                onClick={() => handleEditModalVisible(user?.user_id)}
              >
                Edit
              </Button> */}
              <Button
                size="small"
                type="primary"
                icon={<EyeOutlined />}
                onClick={() => {handleVisible(user?.uid)}}
              >
                View
              </Button>
              &nbsp;
              <Popconfirm
                title="Are you sure？"
                okText="Yes"
                cancelText="No"
                onConfirm={() => showDrawer(user?.uid)}
              >
                <Button size="small" type="danger" icon={<DeleteOutlined />}>
                  Delete
                </Button>
              </Popconfirm>
              <br />
              <br />
              <Button
                size="small"
                type="primary"
                icon={<SettingOutlined />}
                onClick={() => handlePassVisible(user.uid)}
              >
                Change Password
              </Button>
              &nbsp;
            </>
          )
        )
      : "",
  ];

  const Pageheader = (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Finance List"
        subTitle="Finance Management"
       
      ></PageHeader>
    </>
  );

  return (
    <Content>
      {Pageheader}
      <div style={{textAlign:"center",marginTop:"3%",}}><h1>Future Development Part</h1></div>
      {/* <Table
        columns={columns}
        dataSource={rows[0]}
        scroll={{ x: 1000 }}
        sticky
      /> */}
      {/* {EditUsers} */}
    </Content>
  );
};

export default connect(null, {
  getUsers,
  updateAuth,
  updateUserPassword,
  createUsers,
  updateUser,
  deleteUser,
})(FinanceList);
