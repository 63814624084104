import {
  CheckCircleOutlined,
  EditOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  Button,
  Descriptions,
  Divider,
  Empty,
  Form,
  Modal,
  PageHeader,
  Popconfirm,
  Tag,Select ,
  Timeline,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { getRoleHelper } from "../../../actions/role";
import {
  getUsers,
  getUsersById,
  updateAuth,
  updateUser,
} from "../../../actions/user";
import {
  renderDate,
  renderInput,
  renderSelect,
  renderTextField,
} from "../../../components/form";

const { Option } = Select;
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};
const gender = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];
const status = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];
const Wrapper = styled.div`
  padding: 20px;
`;
const Title = styled.div`
  width: 100%;
  background-color: #020202;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 5px;
  text-align: center;
`;
const FinanceDetails = ({
  location,
  getUsersById,
  updateAuth,
  updateUser,
  getRoleHelper,
}) => {
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [Id, setId] = useState("");
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [roles, setRoles] = useState(null);
  const [rname, setRName] = useState("");


  useEffect(async () => {
    window.scrollTo(0, 0);
    const id = new URLSearchParams(location.search).get("user");
    const res1= await getRoleHelper()
    const res = await getUsersById(id);
    setRoles(res1)
    let matchFound = false;
// Iterate over each object in res1
res1.forEach(role => {
  // Check if the uid matches the role_id in res.data
  if (role.uid === res.role_id) {
    matchFound = true;
    console.log("Match found for role_id:", role.name);
    setRName(role.name)
    // Show your message or take any other action
  }
});

if (!matchFound) {
  console.log("No match found for role_id:", res.role_id);
}

      form.setFieldsValue({
        name: res?.name,
        active: res?.active,
        email: res?.email,
        mobile_number: res?.mobile_number,
        // user_id: res?.user_id,
        role: res?.role_id==null ?'': res?.role_id,
      });
    
    setData(res);
  }, []);

  const createRoles = (label, value) => {
    return { label, value };
  };
  const handleRefresh = async () => {
    const id = new URLSearchParams(location.search).get("user");
    const res = await getUsersById(id);
    setData(res);
  };

  const handleStatus = async (role, active) => {
    const code = await updateAuth(role, active);
    if (code === 201) {
      const id = new URLSearchParams(location.search).get("user");
      const res = await getUsersById(id);
      setData(res);
    }
  };
  const onFinish = async (values) => {
    const code = await updateUser(Id,values);
    if (code === 201) {
      handleEditModalVisible();
      setVisibleEdit(false);
      handleRefresh()
    }
  };
  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  const handleEditModalVisible = async (user_id) => {
    setVisibleEdit(true);
    setId(user_id);
  };

  let option = [];
  option = roles
    ? roles.map((item) => createRoles(item.name, item.uid))
    : null;

  const pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="User Details"
      subTitle="Admin Management"
      extra={[
        <>
          <Button
            size="small"
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              handleEditModalVisible(data?.uid);
            }}
          >
            Edit
          </Button><br></br>
         
        </>,
      ]}
    ></PageHeader>
  );

  const detailsuser = (
    <>
      <Wrapper>
        {data ? (
          <>
            <Title>User Information </Title>
            <br />
            <Descriptions>
              <Descriptions.Item label="ID">
                <strong>{data?.user_id}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Name">
                <strong>{data?.name}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Mobile Number">
                <strong>{data?.mobile_number}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Email">
                <strong>{data?.email}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Role">
                <strong>{rname===''?<spain  style={{ color: 'red',fontWeight:'600' }}>unassign</spain>:rname}</strong>
              </Descriptions.Item>
              
              <Descriptions.Item label="Status">
                <>
                  {data?.active ? (
                    <>
                      {/* <Tag color="#87d068">Active</Tag> */}
                      <Popconfirm
                        title="Are you sure？"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() =>
                          handleStatus(data?.uid, false)
                        }
                      >
                        <Button
                          size="small"
                          type="primary"
                          icon={<StopOutlined />}
                        >
                          Activate
                        </Button>
                      </Popconfirm>
                    </>
                  ) : (
                    <>
                      {/* <Tag color="#f50">Inactive</Tag> */}
                      <Popconfirm
                        title="Are you sure？"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() =>
                          handleStatus(data?.uid, true)
                        }
                      >
                        <Button
                          size="small"
                          type="danger"
                          icon={<CheckCircleOutlined />}
                        >
                          Inactive
                        </Button>
                      </Popconfirm>
                    </>
                  )}
                </>
              </Descriptions.Item>
              <br />
            </Descriptions>
          </>
        ) : null}
        {data?.logs?.length>1 ? (
          <>
            <Title>Logs </Title>
            <br />
            {data.logs ? (
              <Timeline mode="left">
                {data.logs.map((item, index) => (
                  <Timeline.Item
                    label={moment
                      .utc(item?.create_at)
                      .format("YYYY-MM-DD hh:mm A")}
                    keys={index}
                  >
                    {item?.message}(user-{item?.admin})
                    
                  </Timeline.Item>
                ))}
              </Timeline>
            ) : (
              <Empty />
            )}
          </>
        ) : 
        <>
            <Title>Logs </Title>
            <br />
            {data.logs ? (
              <Timeline mode="left">
                
                  <Timeline.Item
                    label={moment
                      .utc(data?.logs?.create_at)
                      .format("YYYY-MM-DD hh:mm A")}
                  >
                    {data?.logs?.message}(user-{data?.logs?.admin})
  
                  </Timeline.Item>
                
              </Timeline>
            ) : (
              <Empty />
            )}
          </>
        }
        <br />
      </Wrapper>
    </>
  );

  const EditUser = (
    <>
      <Modal
        title="Update User Informartion"
        centered
        visible={visibleEdit}
        cancelText="Close"
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setVisibleEdit(false)}
        width={800}
      >
        {data ? (
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            style={{ alignSelf: "center" }}
            requiredMark={false}
            form={form}
          >
            
            {/* {renderInput('user_id', 'User ID', [
                    { required: true, message: 'This field is required!' },
                  ])
                } */}
            {renderInput("name", "Full Name", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("email", "Email", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("mobile_number", "Mobile", [
              { required: true, message: "This field is required!" },
              {
                pattern: /^(01)[0-9][0-9]{8}$/,
                message: "Please valid number!",
              },
            ])}
            {renderSelect(
              "active",
              "Status",
              [{ required: true }],
              "Select a option",
              status,
              "none"
            )}
            {roles
              ? renderSelect(
                  "role",
                  "Role",
                  [{ required: true }],
                  "Select a option",
                  option,
                  "none"
                )
              : null}
            
            {renderTextField("remark", "Remark", [
              { required: true, message: "This field is required!" },
            ])}

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Modal>
    </>
  );
 

  return (
    <Content>
      {pageheader}
      {detailsuser}
      {EditUser}
    </Content>
  );
};

export default connect(null, {
  getUsersById,
  updateAuth,
  updateUser,
  getRoleHelper,
})(FinanceDetails);
