import React, { useEffect, useState } from "react";
import { Form, Button, PageHeader, Breadcrumb, Divider, Row, Col } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import { TabletFilled } from "@ant-design/icons";
import history from "../../../history";
import {
  renderCheckbox,
  renderInput,
  renderSelect,
  renderTextField,
} from "../../../components/form";
import { connect } from "react-redux";
import { getRoleById, updateRole } from "../../../actions/role";

const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};
const auth = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];

const EditRole = ({ getRoleById, updateRole, location }) => {
  const [data, setData] = useState(null);
  const [roleId, setRoleId] = useState(null);

  const onFinish = (values) => {
    updateRole(values, roleId);
  };
  const search = location.search;
  const ids = new URLSearchParams(search).get("id");
  const onFinishFailed = async() => {
    
  };
  const getdata = async () => {
    setRoleId(ids);
    try {
      const res = await getRoleById(ids);
      setData(res);
      form.setFieldsValue({
        name: res?.name,
        active: res?.active,
        user_management: res?.role?.user_management === 'a' ? true : false,
        cms_management: res?.role?.cms_management === 'a' ? true : false,
        end_user_management: res?.role?.end_user_management === 'a' ? true : false,
        accounts_management: res?.role?.accounts_management === 'a' ? true : false,
      });
    } catch (error) {
      console.error('Error while fetching role data:', error);
      // Handle error here if needed
    }
  };
  const [form] = Form.useForm();

  useEffect(async () => {
    window.scrollTo(0, 0)
    await getdata()
    
  }, []);

  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Edit Role"
      subTitle="Admin Management"
      extra={[
        <Button
          key="1"
          type="primary"
          icon={<TabletFilled />}
          onClick={() => history.push("/rolelist")}
        >
          Role List
        </Button>,
      ]}
    ></PageHeader>
  );

  const EditRoles = (
    <>
    
      {data ? (
        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ alignSelf: "center" }}
          requiredMark={false}
          form={form}
        >
          <br></br><br></br>
          {renderInput("name", "Role Name", [
            { required: true, message: "Role name is required" },
          ])}
          <Divider orientation="center">Assign role's access</Divider>

          <Row>
            <Col flex="1 1 200px">
              {renderCheckbox("user_management", "User Management", [
                { required: false },
              ])}
              {renderCheckbox("accounts_management", "Account Management", [
                { required: false },
              ])}
              
            </Col>
            <Col flex="1 1 200px">
            {renderCheckbox("cms_management", "CMS Management", [
                { required: false },
              ])}
              {renderCheckbox("end_user_management", "Clients Management", [
                { required: false },
              ])}
            </Col>
          </Row>
          {renderSelect(
            "active",
            "Auth",
            [{ required: true }],
            "Select a option and change input text above",
            auth,
            "none"
          )}
          {renderTextField("remark", "Remark", [
            { required: true, message: "Remark is required" },
          ])}

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      ) : null}
    </>
  );
  return (
    <Content>
      {Pageheader}
      {EditRoles}
    </Content>
  );
};

export default connect(null, { getRoleById, updateRole })(EditRole);
