import  { createGlobalStyle } from "styled-components";
    const GlobalStyle = createGlobalStyle`
    *{
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
        font-family: 'Roboto', 'Mulish', sans-serif;
        
    }
    button{
        }

`;
export default GlobalStyle;