import {
  CheckCircleOutlined,
  EditOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  Button,
  Descriptions,
  Divider,DatePicker,
  Empty,
  Form,
  Modal,
  PageHeader,
  Popconfirm,
  Tag,Select ,
  Timeline,Alert, Space,
  Row,
  Col
} from "antd";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ReactPlayer from "react-player";
import {

  getCmsDetailsAdmin,getDeliveryStatusAdmin
  
} from "../../../actions/cms";
import {
  renderDate,
  renderInput,
  renderSelect,
  renderTextField,
} from "../../../components/form";

const { Option } = Select;
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};
const payment_option = [
  { value: "completed", label: "Completed" },
  { value: "pending", label: "Pending" },
  { value: "due", label: "Due" },
  { value: "failed", label: "Failed" },
  { value: "cancel", label: "Cancel" },
  { value: "", label: "None" },
];
// const payment_option = [
//   { value: "completed", label: "Completed" },
//   { value: "pending", label: "Pending" },
//   { value: "receive", label: "Receive" },
//   { value: "pogress", label: "Pogress" },
//   { value: "edit", label: "Edit" },
//   { value: "revision", label: "Revision" },
//   { value: "revision_in_pogress", label: "Revision In Pogress" },
//   { value: "revision_in_completed", label: "Revision In Completed" },
//   { value: "", label: "None" },
// ];
const service_option = [
  { value: "platinum", label: "Platinum" },
  { value: "gold", label: "Gold" },
  { value: "silver", label: "Silver" },
  { value: "bronze", label: "Bronze" },
  { value: "", label: "None" },
];
const subscription_option = [
  { value: "hourly", label: "Hourly" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "yearly", label: "Yearly" },
  { value: "", label: "None" },
];
const status = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];
const Wrapper = styled.div`
  padding: 20px;
`;
const Title = styled.div`
  width: 100%;
  background-color: #020202;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 5px;
  text-align: center;
`;
const CmsDetails = ({
  location,

  getCmsDetailsAdmin,

  getDeliveryStatusAdmin
}) => {
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visiblepayment, setVisiblePayment] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [deliveryStatus, setDeliveryStatus] = useState(false);
  const [Id, setId] = useState("");
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [forms] = Form.useForm();
  const [roles, setRoles] = useState(null);
  const [rname, setRName] = useState("");
  const onChange = () => {
    // setFlag(flag + 1);
    // if (data2?.length - 1 === flag) setFlag(0);
  };

  useEffect(async () => {
    window.scrollTo(0, 0);
    const id = new URLSearchParams(location.search).get("cms_id");
    const res = await getCmsDetailsAdmin(id);
    console.log(res);
    
      form.setFieldsValue({
        delivery_status: res?.delivery_status,
  

      });
      
    setData(res);
  }, []);

  const handleRefresh = async () => {
    const id = new URLSearchParams(location.search).get("cms_id");
    const res = await getCmsDetailsAdmin(id);
    setData(res);
  };

  const handleTogglePlay = () => {
    setIsPlaying(!isPlaying); // Toggle between play and pause
  };

  const onFinish = async (values) => {
    const code = await getDeliveryStatusAdmin(Id,values);
    if (code === 201) {
      handleEditModalVisible();
      setVisibleEdit(false);
      handleRefresh()
      // form.resetFields();
    }
  };


  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  const handleEditModalVisible = async (user_id) => {
    setVisibleEdit(true);
    setId(user_id);
  };

  const handleEditModalVisiblePayment = async (user_id) => {
    setVisiblePayment(true);
    setId(user_id);
  };


  const pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="CMS Details"
      subTitle="CMS Management"
      extra={[
        <>
          <Button
            size="small"
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              handleEditModalVisible(data?.uid);
            }}
          >
            Delivery Status
          </Button>
        </>,
      ]}
    ></PageHeader>
  );

  const detailscms = (
    <>
   
      <Wrapper>
        {data ? (
          <>
            <Title>Content Information By Clients </Title>
            <br />
            <Descriptions>
              <Descriptions.Item label="Email">
                <strong>{data?.email||"None"}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Content Name">
                <strong>{data?.content_name_user_side||"None"}</strong>
              </Descriptions.Item>
              {/* <Descriptions.Item label="Delivery Status">
                <strong style={{color:"red"}}>{data?.delivery_status||"None"}</strong>
              </Descriptions.Item> */}
              <Descriptions.Item label="Content Types">
                <strong>{data?.types||"None"}</strong>
              </Descriptions.Item>
              {/* <Descriptions.Item label="Review">
                <strong>{data?.review||"None"}</strong>
              </Descriptions.Item> */}
              {/* <Descriptions.Item label="U-Id">
                <strong>{data?.uid}</strong>
              </Descriptions.Item> */}
              <Descriptions.Item label="Upload Status">
                <strong>{data?.upload_status?"Active":"Inactive"||"None"}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Upload Medium">
                <strong>{data?.upload_medium||"None"}</strong>
              </Descriptions.Item>
              {data?.upload_medium=="upload"?
                <Descriptions.Item label="Drive Link">
                <strong>{data?.upload_drive_link||"None"}</strong>
              </Descriptions.Item>:null
              }
              
              <Descriptions.Item label="Download Edited Content">
                <strong>{data?.download||"None"}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Free Revision">
                <strong>{data?.free_revision||"None"}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Paid Revision">
                <strong>{data?.paid_revision||"None"}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Total Paid Revision">
                <strong>{data?.total_paid_revision||"None"}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="created time">
                <strong>{data?.create_at}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="updated time">
                <strong>{data?.update_at||"None"}</strong>
              </Descriptions.Item>
              
            </Descriptions>
          </>
        ) : null}

            {/* <Title>Content Information by Admin </Title>
            <br />
            <Descriptions>
              <Descriptions.Item label="Content Name">
                <strong>{data?.content_name_admin_side||"None"}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Admin Types">
                <strong>{data?.admin_types||"None"}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Admin Upload Status">
                <strong>{data?.edit_upload_status?"Active":"Inactive"||"None"}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Admin Upload Medium">
                <strong>{data?.edit_upload_medium||"None"}</strong>
              </Descriptions.Item>
              {data?.upload_medium!=="upload"?
                <Descriptions.Item label="Admin Edited Drive Link">
                <strong>{data?.edit_upload_drive_link||"None"}</strong>
              </Descriptions.Item>:null
              }
              <Descriptions.Item label="Amount Last Paid">
                <strong>{data?.accounts_data?.amount_last_paid||"None"}</strong>
              </Descriptions.Item>
            </Descriptions> */}
            <Title>Content Show</Title>
            <br></br>
            <Row gutter={[16, 16]}> {/* gutter is the space between columns */}
              <Col span={12}>
                <Title>Raw Content</Title>
                <img src={`https://api.glue.pitetris.com/margaret/v1/content/show/no/${data?.uid}`} alt="Image 1" style={{ width: '100%' }} />
              </Col>
              <Col span={12}>
              <Title>Edited Content</Title>
              {
                data.admin_types==="image"?
                <img src={`https://api.glue.pitetris.com/margaret/v1/content/edit/show/no/${data?.uid}`} alt="Image 1" style={{ width: '100%' }} />:
                data.admin_types==="video"?
                <>
                <ReactPlayer
                // onEnded={onChange}
                url={`https://api.glue.pitetris.com/margaret/v1/content/edit/show/no/${data?.uid}`}
                muted={true}
                playing={isPlaying}
                width="448px" // Set the width to 400 pixels (adjust as needed)
                height="250px" // Set the height to 225 pixels (adjust as needed)
              />
              <Button onClick={handleTogglePlay}>
                {isPlaying ? 'Pause' : 'Play'} {/* Change the Button text based on the play state */}
              </Button>
              </>
                
              :
                data.admin_types===null?
                <h1>no content upload</h1>:
                <h1>another</h1>
              }
              
              </Col>
            </Row>
      {/* {data?.logs?.length>1 ? (
        <>
          <Title>Logs </Title>
          <br />
          {data.logs ? (
            <Timeline mode="left">
              {data.logs.map((item, index) => (
                <Timeline.Item
                  label={moment
                    .utc(item?.create_at)
                    .format("YYYY-MM-DD hh:mm A")}
                  keys={index}
                >
                  {item?.message}(user-{item?.user})
                  
                </Timeline.Item>
              ))}
            </Timeline>
          ) : (
            <Empty />
          )}
        </>
      ) : 
      <>
          <Title>Logs </Title>
          <br />
          {data.logs ? (
            <Timeline mode="left">
              
                <Timeline.Item
                  label={moment
                    .utc(data?.logs?.create_at)
                    .format("YYYY-MM-DD hh:mm A")}
                >
                  {data?.logs?.message}(user-{data?.logs?.user})

                </Timeline.Item>
              
            </Timeline>
          ) : (
            <Empty />
          )}
        </>
      }
        <br /> */}
      </Wrapper>
    </>
  );

  const DeliveryStatus = (
    <>
      <Modal
        title="Update User Informartion"
        centered
        visible={visibleEdit}
        cancelText="Close"
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setVisibleEdit(false)}
        width={800}
      >
        {data ? (
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            style={{ alignSelf: "center" }}
            requiredMark={false}
            form={form}
          >
            
            {/* {renderInput('user_id', 'User ID', [
                    { required: true, message: 'This field is required!' },
                  ])
                } */}
             <Form.Item
          name="delivery_status"
          label="Delivery Status"
          rules={[{ required: true, message: "Field type is required" }]}
        >
          <Select showSearch placeholder="Select One" size="large"  onChange={(e) => {
            setDeliveryStatus(e);
          }}>
            
            <Option value='pending' >Pending</Option>
            <Option value='complete' >Complete</Option>
            <Option value='receive' >Receive</Option>
            <Option value='pogress' >Pogress</Option>
            <Option value='edit' >Edit</Option>
            <Option value='revision' >Revision</Option>
            <Option value='revision_in_pogress' >Revision In Pogress</Option>
            <Option value='revision_in_completed' >Revision In Completed</Option>
            <Option value=" " >None</Option>
            
          </Select>
        </Form.Item>
      
            
            {renderTextField("remark", "Remark", [
              { required: true, message: "This field is required!" },
            ])}

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Modal>
    </>
  );



  return (
    <Content>
      {pageheader}
      {detailscms}
      {/* {DeliveryStatus} */}

    </Content>
  );
};

export default connect(null, {
  getCmsDetailsAdmin,
  getDeliveryStatusAdmin,
  
})(CmsDetails);
