// import axios from "axios";
import history from "../history";
import api from "./apis/index";
import {
  AUTH_ERROR,
  AUTH_SUCCESS,
  ERROR_MESSAGE,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  VALIDATION_AUTH_ERROR,
  VALIDATION_ERROR,
} from "../reducers/type";
import { message } from "antd";


export const EndUserForgetPassword = (email, password) => async (dispatch) => {
    console.log(email, password)
  dispatch({type: LOADING});
  try{
      const response = await api().post(`/v1/user/forgot/front/${email}`, {
             new_password: password},{
              headers: { 'content-type': 'application/json'}
          });
          console.log(response)
          if(response.status === 201){
              
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Password Update succesfully",
              });
              dispatch({type: LOADED})
              history.push('/sucess')
          }
  
  }catch (err) {
    if (err?.response?.status === 400||err?.response?.status === 409) {

      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
    } else if (err?.response?.status === 422) {
      err.response.data.errors.map(item => {
        dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
      });
    } else if (err?.response?.status === 404) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
    } else if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push('/login');
    } else if (err.message === 'Network Error') {
      history.push('/networkError');
    } else {
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
    }
    dispatch({ type: LOADED });
  }
};

export const login = ({userID, password}) => async (dispatch) => {
    
  dispatch({type: LOADING});
  try{
      const response = await api(localStorage.getItem("token")).post('/role-user/login', {
          user_id: userID, password: password},{
              headers: { 'content-type': 'application/json'}
          });
          if(response.status === 201 ){
              localStorage.setItem("token", response.data.access_token);
              localStorage.setItem("name", response.data.name);
              localStorage.setItem("uid", response.data.uid);
              localStorage.setItem("access", JSON.stringify(response.data.role));
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Login succesfully",
              });
              dispatch({type: LOADED})
              history.push('/dashboard')
          }
  
  }catch (err) {
    if (err?.response?.status === 400||err?.response?.status === 409) {

      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
    } else if (err?.response?.status === 422) {
      err.response.data.errors.map(item => {
        dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
      });
    } else if (err?.response?.status === 404) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
    } else if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push('/login');
    } else if (err.message === 'Network Error') {
      history.push('/networkError');
    } else {
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
    }
    dispatch({ type: LOADED });
  }
};

export const logOut = () => async (dispatch) => {
  dispatch({ type: LOADING});
  try{
      // const response = await api(localStorage.getItem("token")).patch("/access/signout");
      // if(response.status === 201)
      // {
          localStorage.clear();
          history.push('/login')
      // }
      dispatch({ type: AUTH_SUCCESS, payload: 'Successfully logout' });
      dispatch({ type: LOADED });
    }catch (err) {
      if (err?.response?.status === 400||err?.response?.status === 409) {
  
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
      } else if (err?.response?.status === 422) {
        err.response.data.errors.map(item => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
        });
      } else if (err?.response?.status === 404) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
      } else if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push('/login');
      } else if (err.message === 'Network Error') {
        history.push('/networkError');
      } else {
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
      }
      dispatch({ type: LOADED });
    }
  };

export const updateOwnPassword = ({ old_password, new_password }) => async (dispatch) => {
  dispatch({ type: LOADING });

  const object = {
    uid: localStorage.getItem('uid'),
    old_password: old_password,
    new_password: new_password,
  };

  try {
    const response = await api(localStorage.getItem("token")).put("/role-user/self/change-password", { ...object });

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload: "Your password is updated successfully" });
      localStorage.setItem('token', response.data.token);
      return response.status;
    }
  } catch (err) {
    if (err?.response?.status === 400||err?.response?.status === 409) {

      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
    } else if (err?.response?.status === 422) {
      err.response.data.errors.map(item => {
        dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
      });
    } else if (err?.response?.status === 404) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
    } else if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push('/login');
    } else if (err.message === 'Network Error') {
      history.push('/networkError');
    } else {
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
    }
    dispatch({ type: LOADED });
  }
};