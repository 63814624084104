// import React, { useEffect, useState } from "react";
// import { Layout, Button } from "antd";
// import styled from "styled-components";
// const { Client, APIErrorCode } = require("@notionhq/client")
// const { Content } = Layout;

// const notion = new Client({
//     auth: 'secret_smj7qZoHhRJ1RUe5blESaqV2xIFbwAL96vWwH1FZAim',
//   })

// const SuccessContent = styled(Content)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
// `;


// const SuccessPage = ({ onContinue }) => {
//     useEffect(async () => {
//         window.scrollTo(0, 0)
//         try {
//             const notion = new Client({ auth: "secret_smj7qZoHhRJ1RUe5blESaqV2xIFbwAL96vWwH1FZAim" })
//             const myPage = await notion.databases.query({
//               database_id: '2172dd839c0a4a79a789cfefae0c1fdb',
//             //   filter: {
//             //     property: "Landmark",
//             //     rich_text: {
//             //       contains: "Bridge",
//             //     },
//             //   },
//             })
//             console.log(myPage,'myPage')
//           } catch (error) {
//             if (error.code === APIErrorCode.ObjectNotFound) {
//               //
//               // For example: handle by asking the user to select a different database
//               //
//             } else {
//               // Other error handling code
//               console.error(error)
//             }
//           }
//       }, []);
    
//   return (
//     <SuccessContent>
//       <div>
//         <h1>Integrate Notion Page</h1>
//         <Button type="primary" onClick={onContinue}>
//           Continue
//         </Button>
        
//       </div>
//     </SuccessContent>
//   );
// };

// export default SuccessPage;
import { PopupButton } from "@typeform/embed-react";
import React, { useEffect, useState } from "react";
import { Layout, Button } from "antd";
import styled from "styled-components";
const { Client, APIErrorCode } = require("@notionhq/client");
const { Content } = Layout;

const notion = new Client({
  auth: "secret_smj7qZoHhRJ1RUe5blESaqV2xIFbwAL96vWwH1FZAim", // Replace with actual token
});

const SuccessContent = styled(Content)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const TypeForm = ({ onContinue }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  // useEffect(async () => {
  //   window.scrollTo(0, 0);

  //   try {
  //     const myPage = await notion.databases.query({
  //       database_id: "Roadmap-2024-88ad61b380aa4700b87e1b0d90c490ac", // Replace with actual ID
  //       // filter: {
  //       //   property: "Landmark",
  //       //   rich_text: {
  //       //     contains: "Bridge",
  //       //   },
  //       // },
  //     });
  //     setData(myPage);
  //   } catch (error) {
  //     if (error.code === APIErrorCode.ObjectNotFound) {
  //       setError("The specified Notion database was not found.");
  //     } else if (error.message?.includes("CORS")) {
  //       setError(
  //         "CORS error encountered. Please refer to the troubleshooting guide."
  //       );
  //       // Provide specific guidance on chosen solution (integration, proxy, etc.)
  //     } else {
  //       setError("An error occurred while fetching data from Notion.");
  //       console.error(error);
  //     }
  //   }
  // }, []);

  return (
    <SuccessContent>
    {/* <Button>pre leanding Typeform</Button> */}
    <div>

    <PopupButton
        id="KHE16IFa"
        style={{ fontSize: 20 }}
        className="my-button border border-gray-400 p-2 bg-black text-white my-14"
      >
        pre leanding Typeform
      </PopupButton>
      <br></br><br></br>
      <PopupButton
        id="jlk4o3sA"
        style={{ fontSize: 20 }}
        className="my-button border border-gray-400 p-2 bg-black text-white my-14"
      >
        Staff/Freelancer/Creative On-Boarding
      </PopupButton>
      <br></br><br></br>
      <PopupButton
        id="Vcb3pZCh"
        style={{ fontSize: 20 }}
        className="my-button border border-gray-400 p-2 bg-black text-white my-14"
      >
        Feedback Form For Members
      </PopupButton>
    </div>
    </SuccessContent>
  );
};

export default TypeForm;
