import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import history from "../../../history";
import {
    createSocialMediaContent,getSocialMediaContent,updateSocialMediaAuth,DeleteSocialMedia
} from "../../../actions/social_media";
import {
  Table,
  Input,
  Button,
  PageHeader,Card,
  Popconfirm,
  Breadcrumb,
  Tag,
  Row,
  Col,
} from "antd";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusCircleFilled,
  ReadOutlined,
  SearchOutlined,
  SettingOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import Remarks from "../../../components/remark";
import CreateUser from "./CreateUser";
import "./social.css"
import ReactPlayer from 'react-player';
import { InstagramEmbed,TikTokEmbed  } from 'react-social-media-embed';
const { Meta } = Card;

const SocialList = ({
    createSocialMediaContent,getSocialMediaContent,updateSocialMediaAuth,DeleteSocialMedia
}) => {
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [palacement, setPlacement] = useState("bottom");
  const [passVisible, setPassVisible] = useState(false);
  const [createUser, setCreateUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [id, setId] = useState("");

  useEffect(async () => {
    window.scrollTo(0, 0)
    const res = await getSocialMediaContent();
    setData(res);
  }, []);

  const Refresh = async () => {
    const res = await getSocialMediaContent();
    setData(res);
  };

  const handlePassVisible = (id) => {
    setId(id);
    setPassVisible(true);

  };


  const createuser = () => {
    setCreateUser(true);
  };
  const showDrawer = (id) => {

    setId(id);
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
    setPassVisible(false);
    setEditUser(false);
    
  };

  const handleRefresh = async () => {
    const res = await getSocialMediaContent();
    setData(res);
  };
  const handleStatus = async (uid, active) => {
    const code = await updateSocialMediaAuth(uid, active);
    if (code === 201) {
      const res = await getSocialMediaContent();
      setData(res);
    }
  };
  const handleDelete = async (uid) => {
    const code = await DeleteSocialMedia(uid);
    if (code === 201) {
      const res = await getSocialMediaContent();
      setData(res);
    }
  };

  const CardSystem = (
    <>
    <br></br>
        {/* <Row gutter={16} >
            {data
                ? data.map((user, i) => (
                   
                    <>
                  
                     {user.types==="Instagram"?
                                  <div style={{ padding: '5px' }}>
                                    <InstagramEmbed
                                  url={user.url_content}
                                  width={325}
                                  height={500}
                                  />
                                 <h1>hello world</h1>
                                  
                                  </div>
                                    :
                                    <div style={{ padding: '5px' }}>
                                  <TikTokEmbed url={user.url_content} width={325} height={500}/>
                                  <h1>hello world</h1>
                                  </div>}
                  
                    </>
                     
                    
                       
                ))
                : null
            }
        </Row> */}
    <Row gutter={16}>
    {data
        ? data.map((user, i) => (
            <div key={i} style={{ padding: '5px' }}>
                {user.types === "Instagram" ? (
                    <>
                        <InstagramEmbed
                            url={user.url_content}
                            width={325}
                            height={500}
                        />
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <h1> Title: {user.name}</h1>
                        <h1>{user.active?<strong style={{ color: '#3ab569' }}>Active</strong>:<strong style={{ color: 'red' }}>Inactive</strong>}</h1>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Popconfirm
                          title="Are you sure?"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => handleDelete(user?.uid)}
                        >
                          <Button
                            size="small"
                            type="dange"
                            icon={<CheckCircleOutlined />}
                          >
                            Delete
                          </Button>
                          </Popconfirm>
                          {
                            user.active?
                     
                          <Popconfirm
                            title="Are you sure?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => handleStatus(user?.uid, false)}
                          >
                            <Button
                              size="small"
                              type="danger"
                              icon={<CheckCircleOutlined />}
                            >
                              Deactive
                            </Button>
                          </Popconfirm>
                          :
           
                          <Popconfirm
                            title="Are you sure?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => handleStatus(user?.uid, true)}
                          >
                            <Button
                              size="small"
                              type="primary"
                              icon={<CheckCircleOutlined />}
                            >
                              Activate
                            </Button>
                          </Popconfirm>

                          }
                       
                        </div>
                        
                    </>
                ) : user.types === "TikTok" ?  (
                    <>
                        <TikTokEmbed
                            url={user.url_content}
                            width={325}
                            height={500}
                        />
                        
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <h1>Title: {user.name}</h1>
                        <h1>{user.active?<strong style={{ color: '#3ab569' }}>Active</strong>:<strong style={{ color: 'red' }}>Inactive</strong>}</h1>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Popconfirm
                          title="Are you sure?"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => handleDelete(user?.uid)}
                        >
                          <Button
                            size="small"
                            type="primary"
                            icon={<CheckCircleOutlined />}
                          >
                            Delete
                          </Button>
                       </Popconfirm>
                       {
                            user.active?
                     
                          <Popconfirm
                            title="Are you sure?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => handleStatus(user?.uid, false)}
                          >
                            <Button
                              size="small"
                              type="danger"
                              icon={<CheckCircleOutlined />}
                            >
                              Deactive
                            </Button>
                          </Popconfirm>
                          :
           
                          <Popconfirm
                            title="Are you sure?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => handleStatus(user?.uid, true)}
                          >
                            <Button
                              size="small"
                              type="primary"
                              icon={<CheckCircleOutlined />}
                            >
                              Activate
                            </Button>
                          </Popconfirm>

                          }
      
                      
                        
                        </div>
                        
                    </>
                ):null}
            </div>
        ))
        : null
    }
</Row>

    </>
);


  const Pageheader = (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Social Media List"
        subTitle="Cms Management"
        extra={[
          <>
            <Button
              key="1"
              type="primary"
              icon={<PlusCircleFilled />}
              onClick={() => createuser()}
            >
              Create New
            </Button>
            &nbsp;
            
          </>,
        ]}
      ></PageHeader>
{createUser? 
      <CreateUser
        title="Social Media Uploader"
        visible={createUser}
        cancel={() => setCreateUser(false)}
        ok={() => setCreateUser(false)}
        api={createSocialMediaContent}
        refresh={handleRefresh}
      />:null}
    </>
  );
  const RemarksDelete = (
    <Remarks
      placement={palacement}
      visible={visible}
      fnc={onClose}
      id={id}
    //   delFnc={deleteUser}
      data={data}
      refresh={handleRefresh}
    />
  );

 
  return (
    <Content>
      {Pageheader}
      {CardSystem}
      {RemarksDelete}
      {/* {EditUsers} */}
    </Content>
  );
};

export default connect(null, {
    createSocialMediaContent,getSocialMediaContent,updateSocialMediaAuth,DeleteSocialMedia
})(SocialList);


// import React, { useEffect, useState } from "react";
// import { connect } from "react-redux";
// import {
//   createSocialMediaContent,
//   getSocialMediaContent,
//   updateSocialMediaAuth,
//   DeleteSocialMedia,
// } from "../../../actions/social_media";
// import {
//   Table,
//   Input,
//   Button,
//   PageHeader,
//   Card,
//   Popconfirm,
//   Breadcrumb,
//   Tag,
//   Row,
//   Col,
// } from "antd";
// import {
//   CheckCircleOutlined,
//   DeleteOutlined,
//   EditOutlined,
//   EyeOutlined,
//   PlusCircleFilled,
//   ReadOutlined,
//   SearchOutlined,
//   SettingOutlined,
//   StopOutlined,
// } from "@ant-design/icons";
// import { Content } from "antd/lib/layout/layout";
// import Remarks from "../../../components/remark";
// import CreateUser from "./CreateUser";
// import "./social.css";

// const { Meta } = Card;

// const SocialList = ({
//   createSocialMediaContent,
//   getSocialMediaContent,
//   updateSocialMediaAuth,
//   DeleteSocialMedia,
// }) => {
//   const [data, setData] = useState([]);
//   const [visible, setVisible] = useState(false);
//   const [palacement, setPlacement] = useState("bottom");
//   const [passVisible, setPassVisible] = useState(false);
//   const [createUser, setCreateUser] = useState(false);
//   const [editUser, setEditUser] = useState(false);
//   const [id, setId] = useState("");

//   useEffect(() => {
//     const fetchData = async () => {
//       const res = await getSocialMediaContent();
//       setData(res);
//     };
//     fetchData();
//   }, []);

//   const Refresh = async () => {
//     const res = await getSocialMediaContent();
//     setData(res);
//   };

//   const handlePassVisible = (id) => {
//     setId(id);
//     setPassVisible(true);
//   };

//   const createuser = () => {
//     setCreateUser(true);
//   };
//   const showDrawer = (id) => {
//     setId(id);
//     setVisible(true);
//   };
//   const onClose = () => {
//     setVisible(false);
//     setPassVisible(false);
//     setEditUser(false);
//   };

//   const handleRefresh = async () => {
//     const res = await getSocialMediaContent();
//     setData(res);
//   };
//   const handleStatus = async (uid, active) => {
//     const code = await updateSocialMediaAuth(uid, active);
//     if (code === 201) {
//       const res = await getSocialMediaContent();
//       setData(res);
//     }
//   };
//   const handleDelete = async (uid) => {
//     const code = await DeleteSocialMedia(uid);
//     if (code === 201) {
//       const res = await getSocialMediaContent();
//       setData(res);
//     }
//   };

//   useEffect(() => {
//     // Load Instagram embed script
//     const script = document.createElement("script");
//     script.async = true;
//     script.src = "https://www.instagram.com/embed.js";
//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   const CardSystem = (
//     <>
//       <br></br>
//       <Row gutter={[16, 16]}>
//         {data
//           ? data.map((user, i) => (
//               <Col key={i} xs={24} sm={12} md={6} lg={6} xl={6}>
//                 <Card
//                   hoverable
//                   className={user.active ? "active-card" : "inactive-card"}
//                   style={{ width: "100%" }}
//                   cover={
//                     <blockquote
//                       className="instagram-media"
//                       data-instgrm-permalink={user.url_content}
//                       data-instgrm-version="12"
//                       style={{
//                         width: "100%",
//                         height: "300px",
//                         margin: "0 auto",
//                         padding: "0",
//                       }}
//                     >
//                       <a href={user.url_content}></a>
//                     </blockquote>
//                   }
//                 >
//                   <Meta
//                     title={
//                       <>
//                         <span>{user.name}</span>
//                         <span style={{ marginLeft: 70 }}>{user.types}</span>
//                       </>
//                     }
//                     description={
//                       <>
//                         {user.active ? (
//                           <>
//                             <Tag color="#87d068">Active</Tag>
//                             <Popconfirm
//                               title="Are you sure?"
//                               okText="Yes"
//                               cancelText="No"
//                               onConfirm={() => handleStatus(user.uid, false)}
//                             >
//                               <Button size="small" type="danger">
//                                 Disable
//                               </Button>
//                             </Popconfirm>
//                           </>
//                         ) : (
//                           <>
//                             <Tag color="#f50">Inactive</Tag>
//                             <Popconfirm
//                               title="Are you sure?"
//                               okText="Yes"
//                               cancelText="No"
//                               onConfirm={() => handleStatus(user.uid, true)}
//                             >
//                               <Button size="small" type="primary">
//                                 Activate
//                               </Button>
//                             </Popconfirm>
//                           </>
//                         )}
//                         &nbsp;&nbsp;&nbsp;
//                         <Popconfirm
//                           title="Are you sure to delete?"
//                           okText="Yes"
//                           cancelText="No"
//                           onConfirm={() => handleDelete(user.uid)}
//                         >
//                           <Button size="small" type="danger">
//                             Delete
//                           </Button>
//                         </Popconfirm>
//                       </>
//                     }
//                   />
//                 </Card>
//               </Col>
//             ))
//           : null}
//       </Row>
//     </>
//   );

//   const Pageheader = (
//     <>
//       <PageHeader
//         ghost={false}
//         onBack={() => window.history.back()}
//         title="Social Media List"
//         subTitle="Cms Management"
//         extra={[
//           <>
//             <Button
//               key="1"
//               type="primary"
//               icon={<PlusCircleFilled />}
//               onClick={() => createuser()}
//             >
//               Create New
//             </Button>
//             &nbsp;
//           </>,
//         ]}
//       ></PageHeader>
//       {createUser ? (
//         <CreateUser
//           title="Social Media Uploader"
//           visible={createUser}
//           cancel={() => setCreateUser(false)}
//           ok={() => setCreateUser(false)}
//           api={createSocialMediaContent}
//           refresh={handleRefresh}
//         />
//       ) : null}
//     </>
//   );
//   const RemarksDelete = (
//     <Remarks
//       placement={palacement}
//       visible={visible}
//       fnc={onClose}
//       id={id}
//       data={data}
//       refresh={handleRefresh}
//     />
//   );

//   return (
//     <Content>
//       {Pageheader}
//       {CardSystem}
//       {RemarksDelete}
//     </Content>
//   );
// };

// export default connect(null, {
//   createSocialMediaContent,
//   getSocialMediaContent,
//   updateSocialMediaAuth,
//   DeleteSocialMedia,
// })(SocialList);


// import React, { useEffect, useState } from "react";
// import { connect } from "react-redux";
// import {
//   createSocialMediaContent,
//   getSocialMediaContent,
//   updateSocialMediaAuth,
//   DeleteSocialMedia,
// } from "../../../actions/social_media";
// import {
//   Table,
//   Input,
//   Button,
//   PageHeader,
//   Card,
//   Popconfirm,
//   Breadcrumb,
//   Tag,
//   Row,
//   Col,
// } from "antd";
// import {
//   CheckCircleOutlined,
//   DeleteOutlined,
//   EditOutlined,
//   EyeOutlined,
//   PlusCircleFilled,
//   ReadOutlined,
//   SearchOutlined,
//   SettingOutlined,
//   StopOutlined,
// } from "@ant-design/icons";
// import { Content } from "antd/lib/layout/layout";
// import Remarks from "../../../components/remark";
// import CreateUser from "./CreateUser";
// import "./social.css";
// import ReactPlayer from 'react-player';

// const { Meta } = Card;

// const SocialList = ({
//   createSocialMediaContent,
//   getSocialMediaContent,
//   updateSocialMediaAuth,
//   DeleteSocialMedia,
// }) => {
//   const [data, setData] = useState([]);
//   const [visible, setVisible] = useState(false);
//   const [palacement, setPlacement] = useState("bottom");
//   const [passVisible, setPassVisible] = useState(false);
//   const [createUser, setCreateUser] = useState(false);
//   const [editUser, setEditUser] = useState(false);
//   const [id, setId] = useState("");

//   useEffect(() => {
//     const fetchData = async () => {
//       const res = await getSocialMediaContent();
//       setData(res);
//     };
//     fetchData();
//   }, []);

//   const Refresh = async () => {
//     const res = await getSocialMediaContent();
//     setData(res);
//   };

//   const handlePassVisible = (id) => {
//     setId(id);
//     setPassVisible(true);
//   };

//   const createuser = () => {
//     setCreateUser(true);
//   };
//   const showDrawer = (id) => {
//     setId(id);
//     setVisible(true);
//   };
//   const onClose = () => {
//     setVisible(false);
//     setPassVisible(false);
//     setEditUser(false);
//   };

//   const handleRefresh = async () => {
//     const res = await getSocialMediaContent();
//     setData(res);
//   };
//   const handleStatus = async (uid, active) => {
//     const code = await updateSocialMediaAuth(uid, active);
//     if (code === 201) {
//       const res = await getSocialMediaContent();
//       setData(res);
//     }
//   };
//   const handleDelete = async (uid) => {
//     const code = await DeleteSocialMedia(uid);
//     if (code === 201) {
//       const res = await getSocialMediaContent();
//       setData(res);
//     }
//   };

//   useEffect(() => {
//     // Load Instagram and TikTok embed scripts
//     const loadScripts = () => {
//       const instagramScript = document.createElement("script");
//       instagramScript.async = true;
//       instagramScript.src = "https://www.instagram.com/embed.js";
//       document.body.appendChild(instagramScript);

//       const tiktokScript = document.createElement("script");
//       tiktokScript.async = true;
//       tiktokScript.src = "https://www.tiktok.com/embed.js";
//       document.body.appendChild(tiktokScript);

//       return () => {
//         document.body.removeChild(instagramScript);
//         document.body.removeChild(tiktokScript);
//       };
//     };
//     loadScripts();
//   }, []);

//   const getMediaContent = (user) => {
//     if (user.types === "Instagram") {
//       return (
//         <blockquote
//           className="instagram-media"
//           data-instgrm-permalink={user.url_content}
//           data-instgrm-version="12"
//           style={{
//             width: "100%",
//             height: "300px",
//             margin: "0 auto",
//             padding: "0",
//           }}
//         >
//           <a href={user.url_content}></a>
//         </blockquote>
//       );
//     } else if (user.types === "TikTok") {
//       return (
//         <blockquote
//           className="tiktok-embed"
//           cite={user.url_content}
//           data-video-id={user.url_content.split("/").pop()}
//           style={{
//             width: "100%",
//             height: "300px",
//             margin: "0 auto",
//             padding: "0",
//           }}
//         >
//           <a href={user.url_content}></a>
//         </blockquote>
//       );
//     } else {
//       return (
//         <ReactPlayer
//           url={user.url_content}
//           width="100%"
//           height="300px"
//           controls
//           playing
//         />
//       );
//     }
//   };

//   const CardSystem = (
//     <>
//       <br></br>
//       <Row gutter={[16, 16]}>
//         {data
//           ? data.map((user, i) => (
//               <Col key={i} xs={24} sm={12} md={6} lg={6} xl={6}>
//                 <Card
//                   hoverable
//                   className={user.active ? "active-card" : "inactive-card"}
//                   style={{ width: "100%" }}
//                   cover={getMediaContent(user)}
//                 >
//                   <Meta
//                     title={
//                       <>
//                         <span>{user.name}</span>
//                         <span style={{ marginLeft: 70 }}>{user.types}</span>
//                       </>
//                     }
//                     description={
//                       <>
//                         {user.active ? (
//                           <>
//                             <Tag color="#87d068">Active</Tag>
//                             <Popconfirm
//                               title="Are you sure?"
//                               okText="Yes"
//                               cancelText="No"
//                               onConfirm={() => handleStatus(user.uid, false)}
//                             >
//                               <Button size="small" type="danger">
//                                 Disable
//                               </Button>
//                             </Popconfirm>
//                           </>
//                         ) : (
//                           <>
//                             <Tag color="#f50">Inactive</Tag>
//                             <Popconfirm
//                               title="Are you sure?"
//                               okText="Yes"
//                               cancelText="No"
//                               onConfirm={() => handleStatus(user.uid, true)}
//                             >
//                               <Button size="small" type="primary">
//                                 Activate
//                               </Button>
//                             </Popconfirm>
//                           </>
//                         )}
//                         &nbsp;&nbsp;&nbsp;
//                         <Popconfirm
//                           title="Are you sure to delete?"
//                           okText="Yes"
//                           cancelText="No"
//                           onConfirm={() => handleDelete(user.uid)}
//                         >
//                           <Button size="small" type="danger">
//                             Delete
//                           </Button>
//                         </Popconfirm>
//                       </>
//                     }
//                   />
//                 </Card>
//               </Col>
//             ))
//           : null}
//       </Row>
//     </>
//   );

//   const Pageheader = (
//     <>
//       <PageHeader
//         ghost={false}
//         onBack={() => window.history.back()}
//         title="Social Media List"
//         subTitle="Cms Management"
//         extra={[
//           <>
//             <Button
//               key="1"
//               type="primary"
//               icon={<PlusCircleFilled />}
//               onClick={() => createuser()}
//             >
//               Create New
//             </Button>
//             &nbsp;
//           </>,
//         ]}
//       ></PageHeader>
//       {createUser ? (
//         <CreateUser
//           title="Social Media Uploader"
//           visible={createUser}
//           cancel={() => setCreateUser(false)}
//           ok={() => setCreateUser(false)}
//           api={createSocialMediaContent}
//           refresh={handleRefresh}
//         />
//       ) : null}
//     </>
//   );
//   const RemarksDelete = (
//     <Remarks
//       placement={palacement}
//       visible={visible}
//       fnc={onClose}
//       id={id}
//       data={data}
//       refresh={handleRefresh}
//     />
//   );

//   return (
//     <Content>
//       {Pageheader}
//       {CardSystem}
//       {RemarksDelete}
//     </Content>
//   );
// };

// export default connect(null, {
//   createSocialMediaContent,
//   getSocialMediaContent,
//   updateSocialMediaAuth,
//   DeleteSocialMedia,
// })(SocialList);


// import React, { useEffect, useState } from "react";
// import { connect } from "react-redux";
// import {
//   createSocialMediaContent,
//   getSocialMediaContent,
//   updateSocialMediaAuth,
//   DeleteSocialMedia,
// } from "../../../actions/social_media";
// import {
//   Table,
//   Input,
//   Button,
//   PageHeader,
//   Card,
//   Popconfirm,
//   Breadcrumb,
//   Tag,
//   Row,
//   Col,
// } from "antd";
// import {
//   CheckCircleOutlined,
//   DeleteOutlined,
//   EditOutlined,
//   EyeOutlined,
//   PlusCircleFilled,
//   ReadOutlined,
//   SearchOutlined,
//   SettingOutlined,
//   StopOutlined,
// } from "@ant-design/icons";
// import { Content } from "antd/lib/layout/layout";
// import Remarks from "../../../components/remark";
// import CreateUser from "./CreateUser";
// import "./social.css";
// import ReactPlayer from 'react-player';

// const { Meta } = Card;

// const SocialList = ({
//   createSocialMediaContent,
//   getSocialMediaContent,
//   updateSocialMediaAuth,
//   DeleteSocialMedia,
// }) => {
//   const [data, setData] = useState([]);
//   const [visible, setVisible] = useState(false);
//   const [palacement, setPlacement] = useState("bottom");
//   const [passVisible, setPassVisible] = useState(false);
//   const [createUser, setCreateUser] = useState(false);
//   const [editUser, setEditUser] = useState(false);
//   const [id, setId] = useState("");

//   useEffect(() => {
//     const fetchData = async () => {
//       const res = await getSocialMediaContent();
//       setData(res);
//     };
//     fetchData();
//   }, []);

//   const Refresh = async () => {
//     const res = await getSocialMediaContent();
//     setData(res);
//   };

//   const handlePassVisible = (id) => {
//     setId(id);
//     setPassVisible(true);
//   };

//   const createuser = () => {
//     setCreateUser(true);
//   };
//   const showDrawer = (id) => {
//     setId(id);
//     setVisible(true);
//   };
//   const onClose = () => {
//     setVisible(false);
//     setPassVisible(false);
//     setEditUser(false);
//   };

//   const handleRefresh = async () => {
//     const res = await getSocialMediaContent();
//     setData(res);
//   };
//   const handleStatus = async (uid, active) => {
//     const code = await updateSocialMediaAuth(uid, active);
//     if (code === 201) {
//       const res = await getSocialMediaContent();
//       setData(res);
//     }
//   };
//   const handleDelete = async (uid) => {
//     const code = await DeleteSocialMedia(uid);
//     if (code === 201) {
//       const res = await getSocialMediaContent();
//       setData(res);
//     }
//   };

//   useEffect(() => {
//     // Load Instagram and TikTok embed scripts
//     const loadScripts = () => {
//       const instagramScript = document.createElement("script");
//       instagramScript.async = true;
//       instagramScript.src = "https://www.instagram.com/embed.js";
//       document.body.appendChild(instagramScript);

//       const tiktokScript = document.createElement("script");
//       tiktokScript.async = true;
//       tiktokScript.src = "https://www.tiktok.com/embed.js";
//       document.body.appendChild(tiktokScript);

//       return () => {
//         document.body.removeChild(instagramScript);
//         document.body.removeChild(tiktokScript);
//       };
//     };
//     loadScripts();
//   }, []);

//   const getMediaContent = (user) => {
//     if (user.types === "Instagram") {
//       return (
//         <div className="media-container">
//           <blockquote
//             className="instagram-media"
//             data-instgrm-permalink={user.url_content}
//             data-instgrm-version="12"
//             style={{
//               width: "100%",
//               height: "100%",
//               margin: "0 auto",
//               padding: "0",
//             }}
//           >
//             <a href={user.url_content}></a>
//           </blockquote>
//         </div>
//       );
//     } else if (user.types === "TikTok") {
//       return (
//         <div className="media-container">
//           <blockquote
//             className="tiktok-embed"
//             cite={user.url_content}
//             data-video-id={user.url_content.split("/").pop()}
//             style={{
//               width: "100%",
//               height: "100%",
//               margin: "0 auto",
//               padding: "0",
//             }}
//           >
//             <a href={user.url_content}></a>
//           </blockquote>
//         </div>
//       );
//     } else {
//       return (
//         <ReactPlayer
//           url={user.url_content}
//           width="100%"
//           height="100%"
//           controls
//           playing
//         />
//       );
//     }
//   };

//   const CardSystem = (
//     <>
//       <br></br>
//       <Row gutter={[16, 16]}>
//         {data
//           ? data.map((user, i) => (
//               <Col key={i} xs={24} sm={12} md={6} lg={6} xl={6}>
//                 <Card
//                   hoverable
//                   className={user.active ? "active-card" : "inactive-card"}
//                   style={{ width: "100%", height: "400px" }}
//                   cover={getMediaContent(user)}
//                 >
//                   <Meta
//                     title={
//                       <>
//                         <span>{user.name}</span>
//                         <span style={{ marginLeft: 70 }}>{user.types}</span>
//                       </>
//                     }
//                     description={
//                       <>
//                         {user.active ? (
//                           <>
//                             <Tag color="#87d068">Active</Tag>
//                             <Popconfirm
//                               title="Are you sure?"
//                               okText="Yes"
//                               cancelText="No"
//                               onConfirm={() => handleStatus(user.uid, false)}
//                             >
//                               <Button size="small" type="danger">
//                                 Disable
//                               </Button>
//                             </Popconfirm>
//                           </>
//                         ) : (
//                           <>
//                             <Tag color="#f50">Inactive</Tag>
//                             <Popconfirm
//                               title="Are you sure?"
//                               okText="Yes"
//                               cancelText="No"
//                               onConfirm={() => handleStatus(user.uid, true)}
//                             >
//                               <Button size="small" type="primary">
//                                 Activate
//                               </Button>
//                             </Popconfirm>
//                           </>
//                         )}
//                         &nbsp;&nbsp;&nbsp;
//                         <Popconfirm
//                           title="Are you sure to delete?"
//                           okText="Yes"
//                           cancelText="No"
//                           onConfirm={() => handleDelete(user.uid)}
//                         >
//                           <Button size="small" type="danger">
//                             Delete
//                           </Button>
//                         </Popconfirm>
//                       </>
//                     }
//                   />
//                 </Card>
//               </Col>
//             ))
//           : null}
//       </Row>
//     </>
//   );

//   const Pageheader = (
//     <>
//       <PageHeader
//         ghost={false}
//         onBack={() => window.history.back()}
//         title="Social Media List"
//         subTitle="Cms Management"
//         extra={[
//           <>
//             <Button
//               key="1"
//               type="primary"
//               icon={<PlusCircleFilled />}
//               onClick={() => createuser()}
//             >
//               Create New
//             </Button>
//             &nbsp;
//           </>,
//         ]}
//       ></PageHeader>
//       {createUser ? (
//         <CreateUser
//           title="Social Media Uploader"
//           visible={createUser}
//           cancel={() => setCreateUser(false)}
//           ok={() => setCreateUser(false)}
//           api={createSocialMediaContent}
//           refresh={handleRefresh}
//         />
//       ) : null}
//     </>
//   );
//   const RemarksDelete = (
//     <Remarks
//       placement={palacement}
//       visible={visible}
//       fnc={onClose}
//       id={id}
//       data={data}
//       refresh={handleRefresh}
//     />
//   );

//   return (
//     <Content>
//       {Pageheader}
//       {CardSystem}
//       {RemarksDelete}
//     </Content>
//   );
// };

// export default connect(null, {
//   createSocialMediaContent,
//   getSocialMediaContent,
//   updateSocialMediaAuth,
//   DeleteSocialMedia,
// })(SocialList);
