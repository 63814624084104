import axios from "axios";
import history from "../history";
import {
  AUTH_ERROR,
  ERROR_MESSAGE,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  VALIDATION_ERROR,VALIDATION_AUTH_ERROR,
} from "../reducers/type";
import api from "./apis/index";

export const createUsers = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  const object = {
    name: data.name,
    email: data.email,
    mobile_number: data.mobile_number,
    password: data.password,
    active: data.authenticated,
    user_id: data.user_id,
    role: data.role,

  };

  try {
    const response = await api(localStorage.getItem("token")).post(
      "/role-user/create",
      { ...object }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      return response.status;
    }
  }catch (err) {
    if (err?.response?.status === 400||err?.response?.status === 409) {

      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
    } else if (err?.response?.status === 422) {
      err.response.data.errors.map(item => {
        dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
      });
    } else if (err?.response?.status === 404) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
    } else if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push('/login');
    }  else if (err?.response?.status === 422 ) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
    } else if (err.message === 'Network Error') {
      history.push('/networkError');
    } else {
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
    }
    dispatch({ type: LOADED });
  }
};

export const getUsers = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/role-user/all"
    );

    dispatch({ type: LOADED });
    return response.data.data;
  }catch (err) {
    if (err?.response?.status === 400||err?.response?.status === 409) {

      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
    } else if (err?.response?.status === 422) {
      err.response.data.errors.map(item => {
        dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
      });
    } else if (err?.response?.status === 404) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
    } else if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push('/login');
    } else if (err.message === 'Network Error') {
      history.push('/networkError');
    } else {
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
    }
    dispatch({ type: LOADED });
  }
};
export const getUserDetails = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/role-user/${id}`
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      return response.data.details;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if (err.message == "Network Error") {
      history.push("/networkError");
      return;
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
  }
};

export const getUsersById = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `role-user/${id}`
    );
    dispatch({ type: LOADED });
    return response.data.data;
  }catch (err) {
    if (err?.response?.status === 400||err?.response?.status === 409) {

      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
    } else if (err?.response?.status === 422) {
      err.response.data.errors.map(item => {
        dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
      });
    } else if (err?.response?.status === 404) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
    } else if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push('/login');
    } else if (err.message === 'Network Error') {
      history.push('/networkError');
    } else {
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
    }
    dispatch({ type: LOADED });
  }
};

export const updateUser = (uId,data) => async (dispatch) => {
  dispatch({ type: LOADING });

  const object = {
    uid:uId,
    name: data.name,
    email: data.email,
    mobile_number: data.mobile_number,
    active: data.active,
    role: data.role,

  };
  dispatch({ type: LOADED });

  try {
    const response = await api(localStorage.getItem("token")).put(
      "/role-user/update",
      { ...object }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "User is updated succesfully",
      });

      history.push("/userlist");
    }
  }catch (err) {
    if (err?.response?.status === 400||err?.response?.status === 409) {

      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
    } else if (err?.response?.status === 422) {
      err.response.data.errors.map(item => {
        dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
      });
    } else if (err?.response?.status === 404) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
    } else if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push('/login');
    } else if (err.message === 'Network Error') {
      history.push('/networkError');
    } else {
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
    }
    dispatch({ type: LOADED });
  }
};

export const updateUserPassword = (user, data) => async (dispatch) => {
  dispatch({ type: LOADING });
  const object = {
    uid: user,
    new_password: data.new_password,
  };
  dispatch({ type: LOADED });

  try {
    const response = await api(localStorage.getItem("token")).put(
      "/role-user/user/change-password",
      { ...object }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "User Password is updated succesfully",
      });
      return response.status;
    }
  }catch (err) {
    if (err?.response?.status === 400||err?.response?.status === 409) {

      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
    } else if (err?.response?.status === 422) {
      err.response.data.errors.map(item => {
        dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
      });
    } else if (err?.response?.status === 404) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
    } else if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push('/login');
    } else if (err.message === 'Network Error') {
      history.push('/networkError');
    } else {
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
    }
    dispatch({ type: LOADED });
  }
};


  export const deleteUser = (id) => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const response = await api(localStorage.getItem("token")).delete(
        `role-user/delete/${id}`
      );
      dispatch({ type: LOADED });
      return response.status;
    }catch (err) {
      if (err?.response?.status === 400||err?.response?.status === 409) {
  
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
      } else if (err?.response?.status === 422) {
        err.response.data.errors.map(item => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
        });
      } else if (err?.response?.status === 404) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
      } else if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push('/login');
      } else if (err.message === 'Network Error') {
        history.push('/networkError');
      } else {
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
      }
      dispatch({ type: LOADED });
    }
  };

export const updateAuth = (user, auth) => async (dispatch) => {
  dispatch({ type: LOADING });

  const object = {
    uid: user,
    active: auth,
  };
  dispatch({ type: LOADED });

  try {
    const response = await api(localStorage.getItem("token")).put(
      "role-user/update/status",
      { ...object }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "User authentication is updated succesfully",
      });

      return response.status;
    }
  }catch (err) {
    if (err?.response?.status === 400||err?.response?.status === 409) {

      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
    } else if (err?.response?.status === 422) {
      err.response.data.errors.map(item => {
        dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
      });
    } else if (err?.response?.status === 404) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
    } else if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push('/login');
    } else if (err.message === 'Network Error') {
      history.push('/networkError');
    } else {
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
    }
    dispatch({ type: LOADED });
  }
};
