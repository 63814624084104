import { Layout } from "antd";
import "antd/dist/antd.css";
import { Content, Footer } from "antd/lib/layout/layout";
import ".././style.css";
import React from "react";
import { connect } from "react-redux";
import MessageAlert from "../components/messageAlert";
import { SpinnerCircular } from 'spinners-react';

import { useState } from "react";
import { css } from "@emotion/react";
import BarLoader from "react-spinners/BarLoader";

import Switcher from "./switcher";
import Sidebar from "./sidebar";
import Topbar from "./Topbar";

const override = css`
  display: flex;
  justify-content:center;
  align-items:center;
  margin: 0 auto;
  border-color: red;
`;
const PageLayout = (props) => {
  let [loading, setLoading] = useState(true);
 

  return (
    <Layout style={{ minHeight: "100vh" }}>
        {props.loader.loading ?
         <BarLoader color="#08adee" loading={loading} css={override} width="100%" size="100%" />:null
      }
       
      <Topbar />
      <Layout className="site-layout" >
        <Sidebar />
        <Layout className="site-layout">
          <Content style={{ margin: "0px", padding: "0px", boxShadow: "none" }}>
            <Switcher />
          </Content>
        </Layout>
      </Layout>
      <Footer style={{ textAlign: "center" }}>
      Copyright © 2024-2025 pi Tetris. All rights reserved.{" "}
      </Footer>
    </Layout>
  );
};
const mapStateToProps = (state) => {
  return {
    loader: state.loader,
  };
};
export default connect(mapStateToProps)(PageLayout);
