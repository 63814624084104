import { Button, Form, Modal, Divider, Input } from "antd";
import { Content } from "antd/lib/layout/layout";
import { getRoles } from "../../../actions/role";
import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import {
  renderInput,
  renderPasswordField,
  renderSelect,
  renderDate,
  renderTextField,
} from "../../../components/form";

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const types = [
  { value: "Instagram", label: "Instagram" },
  { value: "TikTok", label: "TikTok" },

];
const content_categories = [
  { value: "Other", label: "Other" },
  { value: "Vlog", label: "Vlog" },
  { value: "Podcast", label: "Podcast" },
  { value: "Music Industry", label: "Music Industry" },
  { value: "Events", label: "Events" },
  { value: "Tech", label: "Tech" },
  { value: "Automotive", label: "Automotive" },
  { value: "Fashion", label: "Fashion" },
  { value: "Food & Beverage", label: "Food & Beverage" },
  { value: "TechVlog", label: "TechVlog" },


];
const status = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];

const CreateUser = ({ title, visible, cancel,refresh, ok, api }) => {
  const [roles, setRoles] = useState(null)
  const [form] = Form.useForm();
  useEffect(async() => {
    window.scrollTo(0, 0)
    
  }, [])
 
  const onFinish = async (values) => {
    const code = await api(values);
  
    if (code === 201) {
      ok();
      form.resetFields();
      refresh()
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };


  const createRoles = (label,value) => {
    return {label,value}
  }


  const MODAL = (
    <Modal
      title={title}
      centered
      visible={visible}
      width={800}
      onCancel={cancel}
      cancelText="Close"
      okButtonProps={{ style: { display: 'none' } }}
    >
      <Form
        {...layout}
        name="basic"
        
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
        initialValues={{ remember: true }}
        form={form}
        autoComplete={false}
        requiredMark={false}
      >
        
        {renderInput("name", "Name", [
          { required: true, message: "This field is required!" },
        ])}
        
        
        {renderSelect(
          "active",
          "Status",
          [{ required: true }],
          "Select a option",
          status,
          "none"
        )}
        {renderSelect(
          "types",
          "Type",
          [{ required: true }],
          "Select a option",
          types,
          "none"
        )}
        {renderSelect(
          "content_category",
          "Category",
          [{ required: true }],
          "Select a option",
          content_categories,
          "none"
        )}
        <Form.Item
          label={"Link"}
          name="url_content"
          rules={[
              {
                  required: true,
                  message: "This field is required",
              },
              {
                  validator: (_, value) => {
                      if (!value || /^https?:\/\/.*/.test(value)) {
                          return Promise.resolve();
                      }
                      return Promise.reject('Please enter a valid HTTP or HTTPS URL');
                  },
              },
          ]}
      >
          <Input
              name="url_content"
              placeholder="Enter URL"
              // onChange={(e) => handleFileChange(e)}
          />
      </Form.Item>

        
        
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );

  
  return(
    <Content>
    {MODAL}
    </Content>
  );
};

export default connect(null,{getRoles})(CreateUser);
