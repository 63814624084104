import axios from "axios";
import history from "../history";
import {
  AUTH_ERROR,
  ERROR_MESSAGE,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  VALIDATION_ERROR,VALIDATION_AUTH_ERROR,TOTAL_SIZE,DOWNLOADED,PERCENT
} from "../reducers/type";
import api from "./apis/index";
import moment from "moment";
import { message } from "antd";

export const getRssFeedList = () => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const response = await api(localStorage.getItem("token")).get(
        "v1/rss_feed/admin/all"
      );
  
      dispatch({ type: LOADED });
      return response.data.data;
    }catch (err) {
      if (err?.response?.status === 400||err?.response?.status === 409) {
  
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
      } else if (err?.response?.status === 422) {
        err.response.data.errors.map(item => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
        });
      } else if (err?.response?.status === 404) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
      } else if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push('/login');
      } else if (err.message === 'Network Error') {
        history.push('/networkError');
      } else {
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
      }
      dispatch({ type: LOADED });
    }
  };


  export const getRssFeedDetailsAdmin = (id) => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const response = await api(localStorage.getItem("token")).get(
        `v1/rss_feed/admin/${id}`
      );
    console.log(response,"response")
      if (response.status === 200) {
        dispatch({ type: LOADED });
        return response.data.data[0];
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        err.response.data.errors.map((item) => {
          dispatch({
            type: VALIDATION_ERROR,
            payload: [{ message: item.message, path: item.path[0] }],
          });
        });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push("/login");
      }
      if (err.message == "Network Error") {
        history.push("/networkError");
        return;
      }
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response ? err.response.data.message : history.push("/opps"),
      });
      dispatch({ type: LOADED });
    }
  };

  export const getUploadAdminRssFeed = (values,content) => async (dispatch) => {
    dispatch({ type: LOADING });
    const data = new FormData();
    const object = {
      title: values.title,
      active: values.authenticated,
      description: values.description,
      category: values.category,
      time: parseInt(values.time),
      pub_date: new Date(values.pub_date),
      link: values.link,
  
    };
    if(content) data.append("upload_file", content);
      dispatch({ type: LOADED });
  
        try {
          const response = await api(localStorage.getItem("token")).post(
            `v1/rss_feed/admin/upload`, data,{
              headers: {
                "content-type": "multipart/form-data",
                'authorization': `Bearer ${localStorage.getItem("token")}`,
              },
                params:object
            }
          );
          if (response.status === 201) {
            dispatch({ type: LOADED });
            message.success(" Rss Feed upload Succesfully");
            // return history.push('/cmslist')
            return response.status
          }
        } catch (err) {
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
            localStorage.clear();
            history.push("/login");
          }
          if (err?.response?.status === 400 || err?.response?.status === 409) {
            dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
          
          }
          if (err.message == "Network Error") {
            history.push("/networkError");
            return;
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response ? err.response.data.message : history.push("/opps"),
          });
          dispatch({ type: LOADED });
        }
        
      
  
  };


  export const deleteRssFeed = (id) => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const response = await api(localStorage.getItem("token")).delete(
        `v1/rss_feed/delete/${id}`
      );
      dispatch({ type: LOADED });
      return response.status;
    }catch (err) {
      if (err?.response?.status === 400||err?.response?.status === 409) {
  
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
      } else if (err?.response?.status === 422) {
        err.response.data.errors.map(item => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
        });
      } else if (err?.response?.status === 404) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
      } else if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push('/login');
      } else if (err.message === 'Network Error') {
        history.push('/networkError');
      } else {
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
      }
      dispatch({ type: LOADED });
    }
  };


  export const updateRssFeed = (user, auth) => async (dispatch) => {
    dispatch({ type: LOADING });
   
    const object = {
      uid: user,
      active: auth,
    };
    dispatch({ type: LOADED });
  
    try {
      const response = await api(localStorage.getItem("token")).put(
        "v1/rss_feed/update/status",
        { ...object }
      );
  
      if (response.status === 201) {
        dispatch({ type: LOADED });
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: "Rss Feed updated succesfully",
        });
  
        return response.status;
      }
    }catch (err) {
      if (err?.response?.status === 400||err?.response?.status === 409) {
  
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
      } else if (err?.response?.status === 422) {
        err.response.data.errors.map(item => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
        });
      } else if (err?.response?.status === 404) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
      } else if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push('/login');
      } else if (err.message === 'Network Error') {
        history.push('/networkError');
      } else {
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
      }
      dispatch({ type: LOADED });
    }
  };
  export const updateRssFeedfeature = (user, auth) => async (dispatch) => {
    dispatch({ type: LOADING });
   
    const object = {
      uid: user,
      active: auth,
    };
    dispatch({ type: LOADED });
  
    try {
      const response = await api(localStorage.getItem("token")).put(
        "v1/rss_feed/update/featured",
        { ...object }
      );
  
      if (response.status === 201) {
        dispatch({ type: LOADED });
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: "Rss Feed updated succesfully",
        });
  
        return response.status;
      }
    }catch (err) {
      if (err?.response?.status === 400||err?.response?.status === 409) {
  
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail }); // Dispatch the error message to Redux store
      } else if (err?.response?.status === 422) {
        err.response.data.errors.map(item => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ 'message': item.message, 'path': item.path[0] }] });
        });
      } else if (err?.response?.status === 404) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.detail });
      } else if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push('/login');
      } else if (err.message === 'Network Error') {
        history.push('/networkError');
      } else {
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : 'Error occurred' });
      }
      dispatch({ type: LOADED });
    }
  };
  