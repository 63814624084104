
import {
  CheckCircleOutlined,
  EditOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  Button,
  Descriptions,
  Divider,DatePicker,
  Empty,
  Form,
  Modal,
  PageHeader,
  Popconfirm,
  Tag,Select ,
  Timeline,Alert, Space
} from "antd";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import {
  getEndUsers,
  getEndUsersById,
  updateEndUser,
  updateEndUserAuth,updateEndUserPayment
} from "../../../actions/end_user";
import {
  renderDate,
  renderInput,
  renderSelect,
  renderTextField,
} from "../../../components/form";

const { Option } = Select;
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};
const payment_option = [
  { value: "completed", label: "Completed" },
  { value: "pending", label: "Pending" },
  { value: "due", label: "Due" },
  { value: "failed", label: "Failed" },
  { value: "cancel", label: "Cancel" },
  { value: "", label: "None" },
];
// const payment_option = [
//   { value: "completed", label: "Completed" },
//   { value: "pending", label: "Pending" },
//   { value: "receive", label: "Receive" },
//   { value: "pogress", label: "Pogress" },
//   { value: "edit", label: "Edit" },
//   { value: "revision", label: "Revision" },
//   { value: "revision_in_pogress", label: "Revision In Pogress" },
//   { value: "revision_in_completed", label: "Revision In Completed" },
//   { value: "", label: "None" },
// ];
const service_option = [
  { value: "platinum", label: "Platinum" },
  { value: "gold", label: "Gold" },
  { value: "silver", label: "Silver" },
  { value: "bronze", label: "Bronze" },
  { value: "", label: "None" },
];
const subscription_option = [
  { value: "hourly", label: "Hourly" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "yearly", label: "Yearly" },
  { value: "", label: "None" },
];
const status = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];
const Wrapper = styled.div`
  padding: 20px;
`;
const Title = styled.div`
  width: 100%;
  background-color: #020202;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 5px;
  text-align: center;
`;
const UserDetails = ({
  location,
  getEndUsers,
  getEndUsersById,
  updateEndUser,
  updateEndUserAuth,updateEndUserPayment
}) => {
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visiblepayment, setVisiblePayment] = useState(false);
  const [Id, setId] = useState("");
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [forms] = Form.useForm();
  const [roles, setRoles] = useState(null);
  const [rname, setRName] = useState("");


  useEffect(async () => {
    window.scrollTo(0, 0);
    const id = new URLSearchParams(location.search).get("client");
    const res = await getEndUsersById(id);
    
      form.setFieldsValue({
        name: res?.name,
        phone: res?.phone,

      });
      forms.setFieldsValue({
        subscription: res?.subscription||"",
        service: res?.service||"",
        payment_status: res?.payment_status||"",
        subscription_end_time: moment(res?.subscription_end_time)||"",
        subscription_start_time:moment( res?.subscription_start_time)||"",

      });
    
    setData(res);
  }, []);

  const handleRefresh = async () => {
    const id = new URLSearchParams(location.search).get("client");
    const res = await getEndUsersById(id);
    setData(res);
  };

  const handleStatus = async (role, active) => {
    const code = await updateEndUserAuth(role, active);
    if (code === 201) {
      const id = new URLSearchParams(location.search).get("client");
      const res = await getEndUsersById(id);
      setData(res);
    }
  };

  const onFinish = async (values) => {
    const code = await updateEndUser(Id,values);
    if (code === 201) {
      handleEditModalVisible();
      setVisibleEdit(false);
      handleRefresh()
      // form.resetFields();
    }
  };

  const onFinishpayment = async (values) => {
    const code = await updateEndUserPayment(Id,values);
    if (code === 201) {
      handleEditModalVisiblePayment();
      setVisiblePayment(false);
      handleRefresh()
      // forms.resetFields();
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  const handleEditModalVisible = async (user_id) => {
    setVisibleEdit(true);
    setId(user_id);
  };

  const handleEditModalVisiblePayment = async (user_id) => {
    setVisiblePayment(true);
    setId(user_id);
  };


  const pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Client Details"
      subTitle="Client Management"
      extra={[
        <>
          <Button
            size="small"
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              handleEditModalVisible(data?.uid);
            }}
          >
            Edit
          </Button>
           {/* <br></br><Button
            size="small"
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              handleEditModalVisiblePayment(data?.uid);
            }}
          >
            payment
          </Button> */}
        </>,
      ]}
    ></PageHeader>
  );

  const detailsuser = (
    <>
      <Wrapper >
        {data ? (
          <>
            <Title>User Information </Title>
            <br />
            <Descriptions>
              <Descriptions.Item label="Email">
                <strong>{data?.email}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Name">
                <strong>{data?.name}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Mobile Number">
                <strong>{data?.phone}</strong>
              </Descriptions.Item>
              {/* <Descriptions.Item label="Confarmation Link Status">
                <strong>{data?.confarmationlink?data?.confarmationlink:"None"}</strong>
              </Descriptions.Item> */}
              {/* <Descriptions.Item label="payment status">
                <strong>{data?.payment_status?data?.payment_status:"None"}</strong>
              </Descriptions.Item> */}
              {/* <Descriptions.Item label="subscription">
                <strong>{data?.subscription?data?.subscription:"None"}</strong>
              </Descriptions.Item> */}
              {/* <Descriptions.Item label="Service">
                <strong>{data?.service?data?.service:"None"}</strong>
              </Descriptions.Item> */}
              {/* <Descriptions.Item label="community">
                <strong>{data?.community?data?.community:"None"}</strong>
              </Descriptions.Item> */}
              <Descriptions.Item label="welcome messge seen">
                <strong>{data?.welcome_messge_seen?data?.welcome_messge_seen:"None"}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="welcome messge">
                <strong>{data?.welcome_messge?data?.welcome_messge:"None"}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="created time">
                <strong>{data?.create_at}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="updated time">
                <strong>{data?.update_at}</strong>
              </Descriptions.Item>
              
              <Descriptions.Item label="Status">
                <>
                  {data?.active ? (
                    <>
                      {/* <Tag color="#87d068">Active</Tag> */}
                      <Popconfirm
                        title="Are you sure？"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() =>
                          handleStatus(data?.uid, false)
                        }
                      >
                        <Button
                          size="small"
                          type="primary"
                          icon={<StopOutlined />}
                        >
                          Activate
                        </Button>
                      </Popconfirm>
                    </>
                  ) : (
                    <>
                      {/* <Tag color="#f50">Inactive</Tag> */}
                      <Popconfirm
                        title="Are you sure？"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() =>
                          handleStatus(data?.uid, true)
                        }
                      >
                        <Button
                          size="small"
                          type="danger"
                          icon={<CheckCircleOutlined />}
                        >
                          Inactive
                        </Button>
                      </Popconfirm>
                    </>
                  )}
                </>
              </Descriptions.Item>
              <br />
            </Descriptions>
          </>
        ) : null}

            {/* <Title>Finance Information </Title>
            <br />
            <Descriptions>
              <Descriptions.Item label="Total Amount">
                <strong>{data?.accounts_data?.amount_total||"None"}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Due Amount">
                <strong>{data?.accounts_data?.amount_due||"None"}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Amount Last Paid">
                <strong>{data?.accounts_data?.amount_last_paid||"None"}</strong>
              </Descriptions.Item>
            </Descriptions> */}

        {/* {data?.logs?.length>1 ? (
          <>
            <Title>Logs </Title>
            <br />
            {data.logs ? (
              <Timeline mode="left">
                {data.logs.map((item, index) => (
                  <Timeline.Item
                    label={moment
                      .utc(item?.create_at)
                      .format("YYYY-MM-DD hh:mm A")}
                    keys={index}
                  >
                    {item?.message}(user-{item?.user})
                    
                  </Timeline.Item>
                ))}
              </Timeline>
            ) : (
              <Empty />
            )}
          </>
        ) : 
        <>
            <Title>Logs </Title>
            <br />
            {data.logs ? (
              <Timeline mode="left">
                
                  <Timeline.Item
                    label={moment
                      .utc(data?.logs?.create_at)
                      .format("YYYY-MM-DD hh:mm A")}
                  >
                    {data?.logs?.message}(user-{data?.logs?.user})
  
                  </Timeline.Item>
                
              </Timeline>
            ) : (
              <Empty />
            )}
          </>
        }
        <br /> */}
      </Wrapper>
    </>
  );

  const EditUser = (
    <>
      <Modal
        title="Update User Informartion"
        centered
        visible={visibleEdit}
        cancelText="Close"
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setVisibleEdit(false)}
        width={800}
      >
        {data ? (
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            style={{ alignSelf: "center" }}
            requiredMark={false}
            form={form}
          >
            
            {/* {renderInput('user_id', 'User ID', [
                    { required: true, message: 'This field is required!' },
                  ])
                } */}
            {renderInput("name", "Full Name", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("phone", "Mobile", [
              { required: true, message: "This field is required!" },
              {
                pattern: /^(01)[0-9][0-9]{8}$/,
                message: "Please valid number!",
              },
            ])}
           
      
            
            {renderTextField("remark", "Remark", [
              { required: true, message: "This field is required!" },
            ])}

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Modal>
    </>
  );

  const EditUserPayment = (
    <>
      <Modal
        title="Update Payment Informartion"
        centered
        visible={visiblepayment}
        cancelText="Close"
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setVisiblePayment(false)}
        width={800}
      >
        {data ? (
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinishpayment}
            onFinishFailed={onFinishFailed}
            style={{ alignSelf: "center" }}
            requiredMark={false}
            form={forms}
          >
            
            {/* {renderInput('user_id', 'User ID', [
                    { required: true, message: 'This field is required!' },
                  ])
                } */}
            
            {renderSelect(
              "payment_status",
              "Payment Status",
              [{ required: true }],
              "Select a option",
              payment_option,
              "none"
            )}
            {renderSelect(
              "service",
              "Service",
              [{ required: true }],
              "Select a option",
              service_option,
              "none"
            )}
            {renderSelect(
              "subscription",
              "Subscription",
              [{ required: true }],
              "Select a option",
              subscription_option,
              "none"
            )}
            
            <Form.Item
          label="Subscription Start"
          name="subscription_start_time"
          // rules={rules}
        >
          <DatePicker
            style={{ width: "100%" }}
            size="large"
            format="YYYY/MM/DD HH:mm:ss a"
            showTime={{
              defaultValue: moment("00:00:00", "HH:mm:ss a"),
            }}
          />
        </Form.Item>
        <Form.Item
          label="Subscription End"
          name="subscription_end_time"
          // rules={rules}
        >
          <DatePicker
            style={{ width: "100%" }}
            size="large"
            format="YYYY/MM/DD HH:mm:ss a"
            showTime={{
              defaultValue: moment("00:00:00", "HH:mm:ss a"),
            }}
          />
        </Form.Item>
        
                {renderInput('amount_paid', 'Paid Amount', [
                            // { required: true, message: 'This field is required!' },
                          ])
                        }
                <Space
                  direction="vertical"
                  style={{
                    marginLeft: "125px",
                    width: '67%',
                    marginBottom: "20px",
                  }}
                >
                
                  <Alert message={`Last amount paid :${data?.accounts_data?.amount_last_paid||""}`} type="info" />
                  
                </Space>
        {renderInput('amount_due', 'Due Amount', [
                    // { required: true, message: 'This field is required!' },
                  ])
                }
            
               <Space
    direction="vertical"
    style={{
      marginLeft: "125px",
      width: '67%',
      marginBottom: "20px",
    }}
  >
    <Alert message={`Total Amount due: ${data?.accounts_data?.amount_due||""}`} type="error" />
    
  </Space>
  {renderTextField("remark", "Remark", [
              { required: true, message: "This field is required!" },
            ])}
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Modal>
    </>
  );

  return (
    <Content>
      {pageheader}
      {detailsuser}
      {EditUser}
      {/* {EditUserPayment} */}
    </Content>
  );
};

export default connect(null, {
  getEndUsers,
  getEndUsersById,
  updateEndUser,
  updateEndUserAuth,
  updateEndUserPayment
})(UserDetails);