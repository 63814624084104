import { LOADED, LOADING } from "./type";


const initState = {
    loading: null,
}

export default (state = initState , action) => {
    switch (action.type) {
        case LOADING:
            return{
                ...state, 
                loading: true,
            };
        case LOADED:
            return{
                ...state, 
                loading: false,
            };
    
        default:
            return state;
    }
}