import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "./index.css";
import { Layout, Menu } from "antd";
import {
  BankOutlined,
  NodeIndexOutlined ,
  BorderlessTableOutlined,
  BulbFilled,
  CalculatorFilled,
  CarOutlined,
  ClusterOutlined,
  CodeSandboxOutlined,
  CommentOutlined,
  DatabaseOutlined,
  DeliveredProcedureOutlined,
  DollarCircleOutlined,
  DollarOutlined,
  DotChartOutlined,
  DribbbleSquareOutlined,
  DropboxOutlined,
  EnvironmentOutlined,
  EuroCircleOutlined,
  FilterOutlined,
  FontSizeOutlined,
  GlobalOutlined,
  HomeOutlined,
  LineChartOutlined,
  MediumOutlined,
  MessageOutlined,
  MonitorOutlined,
  NotificationOutlined,
  OrderedListOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  PlusSquareOutlined,
  PoundOutlined,
  RocketOutlined,
  SearchOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  ShopTwoTone,
  SoundOutlined,
  TableOutlined,
  TeamOutlined,
  TrophyFilled,
  UnorderedListOutlined,
  UsbFilled,
  UserAddOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
  VerticalAlignTopOutlined,
  VideoCameraAddOutlined,
  BehanceOutlined,
  BarsOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import TypeForm from "./TypeForm";
const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [access, setAccess] = useState(null);

  useEffect(async () => {
    window.scrollTo(0, 0);
    setAccess(JSON.parse(localStorage.getItem("access")));
  }, []);
  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };
  {
    /* <ul class="ant-menu ant-menu-light ant-menu-inline-collapsed ant-menu-root ant-menu-vertical" role="menu"/> */
  }
  return (
   
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      width="280px"
      breakpoint="sm"
      collapsedWidth="80"
      style={{
        backgroundColor: "white",
        boxShadow: "4px 3px 8px 0px rgba(0,0,0,0.10)",
      }}
    >
      <Menu theme="light" defaultSelectedKeys={["1"]} mode="inline">
        
        <Menu.Item key="dashboard" icon={<HomeOutlined />}>
          <Link to="/dashboard">Dashboard</Link>
        </Menu.Item>
        
        {/* Admin_management */}
        {access?.user_management==='a' ? (
          <SubMenu
            key="Admin-management"
            icon={<UserOutlined />}
            title="Admin Management"
          >
            <Menu.Item key="role" icon={<UserAddOutlined />}>
              <Link to="/rolelist">Role</Link>
            </Menu.Item>
            <Menu.Item key="user" icon={<UsergroupAddOutlined />}>
              <Link to="/userlist">User</Link>
            </Menu.Item>
          </SubMenu>
        ) : null}
        {access?.end_user_management==='a' ? (
          <SubMenu
            key="clients-management"
            icon={<TeamOutlined />}
            title="Clients Management"
          >
            <Menu.Item key="clients" icon={<UserSwitchOutlined />}>
              <Link to="/clientlist">Client</Link>
            </Menu.Item>
            {/* <Menu.Item key="demo1" icon={<TrophyFilled />}>
              <Link to="/demo-client">Demo</Link>
            </Menu.Item> */}
          </SubMenu>
        ) : null}
        {/* {access?.accounts_management==='a' ? (
          <SubMenu
            key="finance-management"
            icon={<BankOutlined />}
            title="Finance Management"
          >
            <Menu.Item key="accounts" icon={<CalculatorFilled />}>
              <Link to="/financelist">Clients-Finance</Link>
            </Menu.Item>
         
          </SubMenu>
        ) : null} */}
         {access?.cms_management==='a' ? (
          <SubMenu
            key="CMS-management"
            icon={<TableOutlined />}
            title="CMS Management"
          >
            <Menu.Item key="cms" icon={<VideoCameraAddOutlined />}>
              <Link to="/cmslist">Content</Link>
            </Menu.Item>
            <Menu.Item key="social" icon={<UsbFilled />}>
              <Link to="/social-list">Social Media Content</Link>
            </Menu.Item>
            <Menu.Item key="banner" icon={<BehanceOutlined />}>
              <Link to="/banner-list">Banner</Link>
            </Menu.Item>
            <Menu.Item key="rss-feed" icon={<BarsOutlined />}>
              <Link to="/rss-feed-list">Rss Feed</Link>
            </Menu.Item>
          </SubMenu>
        ) : null}
        {/* <Menu.Item key="notion_client_management" icon={<DatabaseOutlined  />}>
          <a href="https://boatneck-verdict-e02.notion.site/Client-Management-ef39cf1769aa473f9859241f8651c0fb?pvs=4" target="_blank">Notion Client Management</a>
        </Menu.Item>
        <Menu.Item key="notion_finacial_management" icon={<PoundOutlined/>}>
          <a href="https://boatneck-verdict-e02.notion.site/Financial-694da5e17a374f249da628a9a773bb60?pvs=4" target="_blank">Notion Financial Management</a>
        </Menu.Item>
        <Menu.Item key="notion_road_management" icon={<NodeIndexOutlined />}>
          <a href="https://boatneck-verdict-e02.notion.site/Roadmap-2024-88ad61b380aa4700b87e1b0d90c490ac?pvs=4" target="_blank">Notion Road Map</a>
        </Menu.Item>
  */}
        <Menu.Item key="socialjjjj" icon={<UsbFilled />}>
          <Link to="/typeform">TypeForm</Link>
        </Menu.Item>
        
                
        
      </Menu>
    </Sider>
    
  );
};

export default Sidebar;
