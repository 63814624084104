import { Button, Form, Modal, Divider,DatePicker, Input } from "antd";
import { Content } from "antd/lib/layout/layout";
import { getRoles } from "../../../actions/role";
import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import {
  renderInput,
  renderPasswordField,
  renderSelect,
  renderDate,
  renderTextField,
} from "../../../components/form";
import moment from "moment";

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const gender = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];
const status = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];

const CreateBanner = ({ title, visible, cancel,refresh, ok, api }) => {
  const [roles, setRoles] = useState(null)
  const [content, setContent] = useState("")
  const [form] = Form.useForm();
  
 
  const onFinish = async (values) => {
    const code = await api(values,content);
  
    if (code === 201) {
      ok();
      form.resetFields();
      refresh()
    }
  };
  const handleFileChange = (event) => {
  setContent(event.target.files[0])
   
  };
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };


  const createRoles = (label,value) => {
    return {label,value}
  }

  let option = [];
      option = (
        roles ? roles?.map(item => 
          createRoles(item.name , item.uid ))
      : null)
  const MODAL = (
    <Modal
      title={title}
      centered
      visible={visible}
      width={800}
      onCancel={cancel}
      cancelText="Close"
      okButtonProps={{ style: { display: 'none' } }}
    >
      <Form
        {...layout}
        name="basic"
        
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
        initialValues={{ remember: true }}
        form={form}
        autoComplete={false}
        requiredMark={false}
      >

        {renderInput("name", "Name", [
          { required: true, message: "This field is required!" },
        ])}
        {renderSelect(
          "authenticated",
          "Status",
          [{ required: true }],
          "Select a option",
          status,
          "none"
        )}
       <Form.Item
          label={"Upload"}
          name="upload_file"
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Input
            type="file"
            name="upload_file"
            onChange={(e) => handleFileChange(e)}
          />
        </Form.Item>
        
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );

  
  return(
    <Content>
    {MODAL}
    </Content>
  );
};

export default connect(null,{getRoles})(CreateBanner);
