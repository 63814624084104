import {combineReducers} from 'redux';
import errorReducer from './errorReducer';
import loaderReducer from './loaderReducer';
import messageReducer from './messageReducer';
import userReducer from './userReducer';


export default combineReducers({
    users:userReducer,
    loader: loaderReducer,
    errors: errorReducer,
    message: messageReducer,

});